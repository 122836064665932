import _ from "lodash";
import { useEffect, useState, } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./regeneratewithModel.module.scss";

import Dropdown from "rc-dropdown";
import Menu from "rc-menu";

import { ModalItem } from "./ModalItem";

import { ChatType, IChat, IChatModel, IMessage } from "redux/actions";
import { useSelector } from "redux/hooks";
import { useLocation } from 'react-router-dom';

interface IProps {
    chatItem?: IChat;
    onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
    messageIndex?: number;
    message?: IMessage;
}

export const RegenerateWithModel = ({
    chatItem,
    onRegenerate,
    messageIndex,
    message,
}: IProps) => {

    const location = useLocation();
    const { gptModel, theme } = useSelector((state) => state.authReducer);
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const chatModel = useSelector((state) => state.authReducer.gptModel);
    const { newMessages, messages } = useSelector((state) => state.chatReducer);

    const [tab, setTab] = useState<"text" | "image">("text");

    const [filteredModels, setFilteredModel] = useState<IChatModel[]>([]);
    const [regenerateModel, setRegenerateModel] = useState<IChatModel>();

    useEffect(() => {
        if (gptModel?.type?.includes(ChatType.image)) setTab("image");
        else setTab("text");
    }, [gptModel]);

    const handleFilter = () => {
        let filteredModels = chatModels;
        const shouldFilterByDocument = (messages[0]?.files || location.pathname === '/chat/new')

        if (shouldFilterByDocument) {
            if (newMessages.length > 0 && !newMessages[0]?.files)
                filteredModels = chatModels;
            else
                filteredModels = chatModels.filter(model => model.type.includes('document'));
        }
        setFilteredModel(filteredModels);
    }

    useEffect(() => {
        let answerModel = chatModels.filter(model => model.id === message?.regenerate_model_id);
        setRegenerateModel(answerModel[0]);
    }, [message !== null])

    return (
        <>
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu
                        style={{
                            width: '220px',
                            height: 'auto',
                            maxHeight: '250px',
                            borderRadius: '10px',
                            border: 'none',
                            backgroundColor:
                                theme === "dark"
                                    ? "#131314"
                                    : "#ffffff",
                            backdropFilter: 'blur(25px)',
                            boxShadow: ' 0px 8px 18px 0px rgba(120, 120, 120, 0.1)',
                            overflow: 'hidden',
                            position: 'relative',
                            marginLeft: '-70px'
                        }}
                    >
                        <div className="flex justify-center align-center text-center font-semibold text-[12px] mt-6 leading-[13.31px]" style={{ color: theme === 'dark' ? 'rgba(255, 255, 255, 1)' : '' }}>
                            <FormattedMessage id="answer.select.regenerate" />
                        </div>
                        <div className={styles.DropDownbody}>
                            {chatModels.length > 0 ? (
                                filteredModels
                                    .filter((model) => model?.type?.includes(tab))
                                    .map((model) => (
                                        <ModalItem
                                            key={model.id}
                                            chatModel={model}
                                            onRegenerate={onRegenerate}
                                            messageIndex={messageIndex}
                                        />
                                    ))
                            ) : (
                                <div className={styles.message}>No model to show</div>
                            )}
                        </div>
                    </Menu>
                }
            >
                <div>
                    <div
                        className={classNames(styles.icon, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}
                        style={{display:'none'}}
                    >
                        <img data-testid='regeneration-model-filter' src={regenerateModel?.image_url ?? chatItem?.model?.image_url ?? chatModel?.image_url ?? "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"} height={'24px'} width={'24px'} onClick={handleFilter} />
                    </div>
                </div>
            </Dropdown>
        </>
    );
};
