import { FormattedMessage } from "react-intl";

import { CloseIcon, PlusIcon } from "../Icons";
import { BackIcon } from "views/layout/Navbar/components/icons";
import { useWindowSize } from "hooks/useWindowSize";

import { useSelector } from "redux/hooks";

interface IHeader {
  showAddPrompt: boolean;
  onClick: () => void;
  onClose: () => void;
  allowEdit?: boolean;
}

export const Header = ({ onClick, onClose, showAddPrompt, allowEdit }: IHeader) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();

  return (
    <div className="grid gap-2 grid-cols-3 grid-rows-1 mb-6">
      {showAddPrompt ? (
        <div className="hover:cursor-pointer" data-testid="back-button" onClick={onClick}>
          <BackIcon prompt={true} theme={theme} />
        </div>                      
      ) : (
        <div
          className="flex flex-row items-center text-primary text-md hover:cursor-pointer"
          onClick={onClick}
          data-testid="add-prompt"
        >
          <PlusIcon />
          <span className="font-bold ">
            <FormattedMessage id="prompt.add.library" />
          </span>
        </div>
      )}
        <div className="flex justify-center ">
          <span className={width <= 490 ? "font-bold text-lg" : "font-bold text-xl"}>
            <FormattedMessage
              id={allowEdit? "prompt.edit.library":   showAddPrompt ? "prompt.add.library" : "prompt.library"}
            />
          </span>
        </div>
      <span
        data-testid="prompt-close"
        className="flex items-center justify-end hover:cursor-pointer"
        onClick={onClose}
      >
        <CloseIcon />
      </span>
    </div>
  );
};
