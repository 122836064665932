import { useState, useRef, Dispatch, SetStateAction} from "react";

import { Modal } from "components/Modal";
import { AddPrompt } from "./AddPrompt";
import { SearchField } from "views/layout/Sidebar/component/ChatSidebar/components/SearchField";
import { Prompts } from "./Prompts";
import { Header } from "./Header";

interface IPromptLibrary {
  onClose: () => void;
  setMessage: Dispatch<SetStateAction<string>>;
  onTextareaFocus: () => void;
  promptLoading: boolean;
  userPromptLoading: boolean;
  textareaRef: any;
}

export const PromptLibrary = ({
  onClose,
  setMessage,
  onTextareaFocus,
  promptLoading,
  userPromptLoading,
  textareaRef,
}: IPromptLibrary) => {
  const [showAddPrompt, setAddPromptModel] = useState<boolean>(false);

  const [editPrompt, setEditPrompt] = useState<number | null>(null);
  const [allowEdit, setAllowEditPrompt] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSelectPrompt = (prompt: string) => {
    setMessage(prompt);
    setTimeout(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.focus();
        const start = prompt.indexOf("{");
        const end = prompt.indexOf("}");
        if (start > 0 && end > 0) {
          const selected = prompt.slice(start, end + 1);
          const startIndex = textarea?.value.indexOf(selected);
          const endIndex = startIndex + selected.length;
          textarea.setSelectionRange(startIndex, endIndex);
        }
      }
    }, 10);
    onTextareaFocus();
    setAddPromptModel(false);
    onClose();
  };

  const onClearSearchField = () => {
    setSearchQuery("");
    if (inputRef.current) inputRef.current.focus();
  };

  const handleSerchFocus = () => {
    if (inputRef.current) inputRef.current.focus();
  }

  const toggleAddPrompt = () =>
    setAddPromptModel((prevState) => !prevState);

  const toggleEditPrompt = () =>
    setAllowEditPrompt((prevState) => !prevState);

  return (
    <Modal onClose={onClose} size="md" >
      <div className="py-[20px] px-[30px] text-light dark:text-prompt-dark">
        <Header
          onClick={!allowEdit ? toggleAddPrompt : toggleEditPrompt}
          onClose={() => {
            onClose();
            setAddPromptModel(false);
            setAllowEditPrompt(false);
          }}
          allowEdit={allowEdit}
          showAddPrompt={showAddPrompt || allowEdit}
        />

        {!showAddPrompt && !allowEdit ? (
          <>
            <SearchField
              inputRef={inputRef}
              value={searchQuery}
              isPrompt={true}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClear={onClearSearchField}
              prompt={true}
              handleSerchFocus={handleSerchFocus}
              autoFocus
            />
            <Prompts
              handleSelectPrompt={handleSelectPrompt}
              promptLoading={promptLoading}
              searchQuery={searchQuery}
              userPromptLoading={userPromptLoading}
              setEditPrompt={setEditPrompt}
              setAllowEditPrompt={setAllowEditPrompt}
            />
          </>
        ) : (
          <AddPrompt toggleAddPrompt={toggleAddPrompt} toggleEditPrompt={toggleEditPrompt} editPrompt={editPrompt} allowEdit={allowEdit} />
        )}
      </div>
    </Modal>
  );
};
