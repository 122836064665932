import { Dispatch, SetStateAction } from "react";
import { validateFileSize, validateFileType } from "./functions";
// import { IUploadFile } from "pages/ChatPage";
import { IErrorMessage, IFileErrorState } from "pages/ChatPage/pages"; 
import { IUserDetail } from "redux/actions";


const AllowedFileTypes = [
    "pdf",
    "txt",
    "ppt",
    "pptx",
    // "doc",
    "docx",
    "csv",
    "xls",
    "xlsx",
    "eml",
    "srt",
    "png",
    "jpg",
    "jpeg",
    "webp",
    "gif"
];
const AllowedFileSize = 100;
const FreePlanAllowedFileSize = 10;
const StandardPlanAllowedFileSize = 50;


export const validateImgFile = (
  files: File[],
  setErrorMessage: Dispatch<SetStateAction<IErrorMessage[]>> | undefined,
  claudAllowedFileSize: number,
  formatMessage: any
): boolean => {
  let isValid = true;
  let allowedFileSize = claudAllowedFileSize;

  for (let file of files) {
    if (!validateFileType({ file: file, types: AllowedFileTypes })) {
      const formattedErrorMessage = formatMessage(
        { id: "documentChat.file.fileFormatImage" },
        { name: file.name }
      );
      setErrorMessage &&
        setErrorMessage((prevErrors) => [
          ...prevErrors,
          { name: file.name, error: formattedErrorMessage },
        ]);
      isValid = false;
      break;
    }

    if (
      !validateFileSize({
        file,
        sizeOptions: { size: allowedFileSize, unit: "MB" },
      })
    ) {
      const formattedErrorMessage = formatMessage(
        { id: "documentChat.file.fileSizeImage" },
        { name: file.name, size: allowedFileSize }
      );
      setErrorMessage &&
        setErrorMessage((prevErrors) => [
          ...prevErrors,
          { name: file.name, error: formattedErrorMessage },
        ]);
      isValid = false;
      break;
    }
  }

  return isValid;
};


export const validateFile = (
  files: File[],
  setIsMainScreenOpen: Dispatch<SetStateAction<boolean>> | undefined,
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>,
  userDetail: IUserDetail | undefined,
  setMessageId?: Dispatch<SetStateAction<string>>,
    // uploadingFiles?: IUploadFile[]
): boolean => {
  let isValid = true;
  const planName = userDetail?.user.activeSubscription.name.toLowerCase();

  if (!files || files.length === 0) {
    setErrorModal({ message: "documentChat.file.required", show: true });
    setIsMainScreenOpen?.(true);
    return false;
  }

  //  const totalFilesCount =
  //    (uploadingFiles ? uploadingFiles.length : 0) + files.length;

  // // if (totalFilesCount > 1) {
  // //   setMessageId?.("documentChat.plan.max_count");
  // //   return false;
  // // }
    for (let file of files) {
      if (!validateFileType({ file, types: AllowedFileTypes })) {
        setErrorModal({ message: "documentChat.file.fileFormat", show: true });
        setIsMainScreenOpen?.(true);
        isValid = false;
        break;
      }

      let allowedFileSize = AllowedFileSize;

      if (planName === "free") {
        allowedFileSize = FreePlanAllowedFileSize;
      } else if (planName === "standard") {
        allowedFileSize = StandardPlanAllowedFileSize;
      }

      const valid = validateFileSize({
        file,
        sizeOptions: { size: allowedFileSize, unit: "MB" },
      });

      if (!valid) {
        if (planName === "free" || planName === "standard") {
          setMessageId &&
            setMessageId(`documentChat.file.${planName}.plan.fileSize`);
        } else {
          setErrorModal({ message: "documentChat.file.fileSize", show: true });
          setIsMainScreenOpen?.(true);
        }
        isValid = false;
        break;
      }
    }
  return isValid;
};



// export const validateImgFile = (files: File[], setUploadingFiles: Dispatch<SetStateAction<IUploadFile[]>> | undefined, setErrorMessage: Dispatch<SetStateAction<IErrorMessage[]>> | undefined, claudAllowedFileSize: number, formatMessage: any): File[] => {
//     const validFiles: File[] = [];
//     let allowedFileSize = claudAllowedFileSize;

//     files.forEach((file) => {
//         setUploadingFiles!(prev => [{ file, status: 'validating', fileType: 'image' }, ...prev]);
//         if (validateFileType({ file: file, types: AllowedFileTypes })) {
//             const valid = validateFileSize({
//                 file: file,
//                 sizeOptions: { size: allowedFileSize, unit: "MB" },
//             });
//             if (valid) {
//                 validFiles.push(file);
//             } else {
//                 const formattedErrorMessage = formatMessage(
//                     { id: 'documentChat.file.fileSizeImage' },
//                     { name: file.name, size: allowedFileSize }
//                 );

//                 setErrorMessage!(prevErrors => [
//                     ...prevErrors,
//                     { name: file.name, error: formattedErrorMessage }
//                 ]);
//                 setUploadingFiles!(prev => {
//                     const updatedFiles: IUploadFile[] = prev.map(f =>
//                         f.file === file ? { ...f, status: 'error' as 'error' } : f
//                     );
//                     return updatedFiles.filter(f => f.status !== 'error');
//                 });
//             }
//         } else {
//             const formattedErrorMessage = formatMessage(
//                 { id: 'documentChat.file.fileFormatImage' },
//                 { name: file.name }
//             );
//             setErrorMessage!(prevErrors => [
//                 ...prevErrors,
//                 { name: file.name, error: formattedErrorMessage }
//             ]);

//             setUploadingFiles!(prev => {
//                 const updatedFiles: IUploadFile[] = prev.map(f =>
//                     f.file === file ? { ...f, status: 'error' as 'error' } : f
//                 );
//                 return updatedFiles.filter(f => f.status !== 'error');
//             });
//         }
//     });

//     return validFiles;
// };

// export const validateFile = (files: File[], setIsMainScreenOpen: Dispatch<SetStateAction<boolean>> | undefined, setUploadingFiles: Dispatch<SetStateAction<IUploadFile[]>> | undefined, setErrorModal: Dispatch<SetStateAction<IFileErrorState>>, userDetail: IUserDetail | undefined, setMessageId?: Dispatch<SetStateAction<string>>): File[] => {
//     const validFiles: File[] = [];
//     if (files && files.length > 0) {

//         files.forEach((file) => {
//             setUploadingFiles!(prev => [{ file, status: 'validating', fileType: 'document' }, ...prev]);
//             if (validateFileType({ file: file, types: AllowedFileTypes })) {
//                 let allowedFileSize = AllowedFileSize;
//                 const planName = userDetail?.user.activeSubscription.name.toLowerCase();
//                 if (planName === "free") {
//                     allowedFileSize = FreePlanAllowedFileSize;
//                 } else if (planName === "standard") {
//                     allowedFileSize = StandardPlanAllowedFileSize;
//                 }
//                 const valid = validateFileSize({
//                     file: file,
//                     sizeOptions: { size: allowedFileSize, unit: "MB" },
//                 });
//                 if (valid) {
//                     validFiles.push(file);
//                 } else {
//                     if (planName === "free" || planName === "standard") {
//                         setMessageId!(`documentChat.file.${planName}.plan.fileSize`);
//                     } else {
//                         setErrorModal({
//                             message: "documentChat.file.fileSize",
//                             show: true,
//                         });
//                     }
//                     setIsMainScreenOpen!(true);
//                 }
//             } else {
//                 setErrorModal({
//                     message: "documentChat.file.fileFormat",
//                     show: true,
//                 });
//                 setIsMainScreenOpen!(true);
//                 return false;
//             }
//         })

//     } else {
//         setErrorModal({ message: "documentChat.file.required", show: true });
//     }
//     return validFiles;
// };