import { EThemeType } from "redux/reducers";

export const InfoIcon = ({ theme }: { theme?: EThemeType }) => {
  const color = theme === "dark" ? "white" : "#353535";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle
        cx="12.0018"
        cy="11.9998"
        r="9.00375"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15.5012H13.3103"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1602 15.5018V11.25H11.0098"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0999 8.2463C12.0999 8.38443 11.9879 8.49641 11.8498 8.49641C11.7117 8.49641 11.5997 8.38443 11.5997 8.2463C11.5997 8.10817 11.7117 7.9962 11.8498 7.9962"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8517 7.99527C11.9898 7.99527 12.1018 8.10725 12.1018 8.24538"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
