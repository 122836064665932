interface IProps {
  color?: string;
  chatList?: boolean;
}

export const DocumentIcon = ({ color = "#FFFFFF", chatList }: IProps) => {
  return (
    <svg
      width={chatList? "16" : "22"}
      height={chatList? "16":  "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
          d="M7.3105 16.271H14.6897V14.896H7.3105V16.271ZM7.3105 12.3752H14.6897V11.0002H7.3105V12.3752ZM5.04175 20.1668C4.67508 20.1668 4.35425 20.0293 4.07925 19.7543C3.80425 19.4793 3.66675 19.1585 3.66675 18.7918V3.2085C3.66675 2.84183 3.80425 2.521 4.07925 2.246C4.35425 1.971 4.67508 1.8335 5.04175 1.8335H13.3147L18.3334 6.85225V18.7918C18.3334 19.1585 18.1959 19.4793 17.9209 19.7543C17.6459 20.0293 17.3251 20.1668 16.9584 20.1668H5.04175ZM12.6272 7.471V3.2085H5.04175V18.7918H16.9584V7.471H12.6272Z"
          fill={color}
        />
    </svg>
  );
};
