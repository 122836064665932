import { useEffect, useState, useRef, memo, lazy, Suspense, Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./ChatSidebar.module.scss";
import { NavLink } from "react-router-dom";

import {
  PlanIcon,
  SettingIcon,
  FreeCreditsIcon,
  PlusIcon
} from "./components/icons";

import { Separator } from "components";
import { SearchField } from "./components/SearchField";
import { ThemeSwitch } from "./components/ThemeSwitch";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { IChat } from "redux/actions";
import { useSelector } from "redux/hooks";
import links from "core/links";
import { ChatRoute } from "pages/ChatPage";
import { ChatSideBarFooter } from "./components/ChatSideBarFooter";
import { ChatItemList } from "./components/ChatItemList/ChatItemList";
import { useWindowSize } from "hooks/useWindowSize";

const FreeCreditsModal = lazy(() =>
  import("pages/ChatPage/components/freeCreditsModal").then((module) => ({
    default: module.FreeCreditsModal,
  }))
);
interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined) => void;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  isMainScreenOpen?: boolean;
  toggleSidebar?: any;
  itemStyles?: any;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  searchQuery?: string; setSearchQuery?: Dispatch<SetStateAction<string>>;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
}

export const ChatSidebar = memo(
  ({
    onSelectChatItem,
    onStartNewChat, isMainScreenOpen, toggleSidebar, itemStyles,
    setChatHistory,
    chatHistoryPagination,
    searchQuery, setSearchQuery,
    setOpenHistory
  }: IProps) => {

    const { pathname, includeRoute } = useRouter();
    const { width } = useWindowSize();
    const chatId = pathname.split("/")[3];
    const chatHistory = useSelector((state) => state.chatReducer.chats);
    const { userDetail, theme } = useSelector((state) => state.authReducer);

    const { newMessages } = useSelector(
      (state) => state.chatReducer
    );

    const [showReferralModal, setShowReferralModal] = useState<boolean>(false);

    const [selectedItem, setSelectedItem] = useState<IChat | undefined>(
      undefined
    );
    const inputRef = useRef<HTMLInputElement | null>(null);

    const onClearSearchField = () => {
      setSearchQuery && setSearchQuery("");
      if (inputRef.current) inputRef.current.focus();
    };

    useEffect(() => {
      if (chatId) {
        const index = chatHistory.findIndex(
          (item) => item.id === JSON.parse(chatId)
        );
        if (index >= 0) setSelectedItem(chatHistory[index]);
      } else {
        setSelectedItem(undefined);
      }
    }, [chatId, chatHistory]);

    const isButtonActive = (): boolean => {
      let isActive = false;
      
      if (includeRoute(ChatRoute.History) || (newMessages.length > 0)) {
        isActive = true;
      } else if (includeRoute(ChatRoute.New) && !isMainScreenOpen) {
        isActive = true;
      }
      return isActive;
    };

    const handleSerchFocus = () => {
      if (inputRef.current) inputRef.current.focus();
    }

    return (
      <>
        <div className={styles.container}>
          <div className={styles.themeallow}>
            <button
              className={classNames(styles.newChatBtn, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
                [styles.isActive]: isButtonActive(),
              })}
              onClick={() => {
                if (isButtonActive()) onStartNewChat?.({ toChat: true });
              }}
              data-testid='new-chat-btn'
            >
              <div
                className={classNames(styles.btnContent, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                  [styles.isActive]: isButtonActive(),
                })}
              >
                <PlusIcon />
                <div className={styles.text}>
                  <FormattedMessage id="sidebar.chat.newChat.btn.text" />
                </div>
              </div>
            </button>
          </div>
          <Separator />
          <SearchField
            inputRef={inputRef}
            value={searchQuery}
            onChange={(e) => { setSearchQuery && setSearchQuery(e.target.value) }}
            onClear={onClearSearchField}
            chatsidebar={true}
            handleSerchFocus={handleSerchFocus}
          />
          <Separator />
          <ChatItemList
            onSelectChatItem={onSelectChatItem}
            setChatHistory={setChatHistory}
            chatHistoryPagination={chatHistoryPagination}
            searchQuery={searchQuery || ''}
            toggleSidebar={toggleSidebar}
            width={width}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setOpenHistory={setOpenHistory}
          />
          <div className={styles.footer}>
            <div className={styles.Hidefootersetting}>
              <ChatSideBarFooter itemStyles={itemStyles} toggleSidebar={toggleSidebar} userDetail={userDetail} theme={theme} />
            </div>
            <div className={styles.themeallow}>
              <div
                className={classNames(styles.itemContainer, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                onClick={() => setShowReferralModal(true)}
                data-testid='sidebar-chat-newchat-earnCredit'
              >
                <FreeCreditsIcon />
                <div className={styles.itemText}>
                  <FormattedMessage id="sidebar.chat.newChat.earnCredits" />
                </div>
              </div>
              <Separator height="18px" />
              {userDetail?.user.team.role === "admin" && (
                <>
                  <NavLink
                    className={classNames(styles.itemContainer, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}                               
                    to={`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`}
                    data-testid='chat-sidebar-currentplan'
                  >
                    <PlanIcon />
                    <div className={styles.itemText}>
                      <FormattedMessage id="sidebar.chat.newChat.currentPlan" />
                    </div>
                    <div className={styles.usageTag}>
                      {userDetail.user.activeSubscription.name}
                    </div>
                  </NavLink>
                  <Separator height="18px" />
                </>
              )}
              <a
                href={links.ChromeStorePluginUrl}
                target="_blank"
                rel="noreferrer"
                className={classNames(styles.itemContainer, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                data-testid='chrome-store-plugin'
              >
                <img
                  src="/ChromeIcon.svg"
                  height={'22px'}
                  width={'22px'}
                  style={{ backgroundColor: "transparent" }}
                />
                <div className={styles.itemText}>
                  <FormattedMessage id="sidebar.chat.newChat.addToChrome" />
                </div>
              </a>
              <Separator height="18px" />
              <NavLink
                className={classNames(styles.itemContainer, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                to={`/${RoutePaths.Settings}`}
                data-testid='sidebar-setting'
              >
                <SettingIcon />
                <div className={styles.itemText}>
                  <FormattedMessage id="sidebar.chat.newChat.settings" />
                </div>
              </NavLink>
            </div>
          </div>
          <ThemeSwitch />
        </div>
        <Suspense fallback={<></>}>
          {showReferralModal && (
            <FreeCreditsModal onClose={() => setShowReferralModal(false)} />
          )}
        </Suspense>
      </>
    );
  }
);