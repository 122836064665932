import { useEffect, useState, Dispatch, SetStateAction, useRef } from "react";
import { ChatItem } from "../ChatItem/ChatItem";
import { ChatSearchItem } from "../ChatItem/ChatSearchItem";
import { getAllChats, IChat, removeMultipleChat } from "redux/actions";
import { LoadingPage } from "components";
import styles from "../../ChatSidebar.module.scss";
import { Spinner } from "components";
import { FormattedMessage } from "react-intl";
import { useAppNotification } from "hooks/services/AppNotification";
import { DownArrow } from "../icons/DownArrow";
import { DeleteHistory } from "../icons/DeleteHistory";
import { HistoryIcon } from "views/layout/Navbar/components/icons/HistoryIcon";
import { DeleteChatConfirm } from "../DeleteChatConfirm";
import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute } from "pages/ChatPage";

interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined) => void;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  searchQuery: string;
  toggleSidebar?: any;
  width?: number;
  setSelectedItem: (chatItem: IChat | undefined) => void;
  selectedItem?: IChat | undefined;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
}

export const ChatItemList = ({
  onSelectChatItem,
  setChatHistory,
  chatHistoryPagination,
  searchQuery,
  toggleSidebar,
  width,
  setSelectedItem,
  selectedItem,
  setOpenHistory
}: IProps) => {

  const { triggerNotification } = useAppNotification();
  const contentRef = useRef<HTMLDivElement>(null);

  const [isloading, setIsloading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [isloadingMore, setIsloadingMore] = useState<boolean>(false);
  const [perPage, setPerPage] = useState(0);
  const [selectedChatIds, setSelectedChatIds] = useState<number[]>([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
  const [chatDelConfirm, setChatDelConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { push } = useRouter();

  const showDelete = selectedChatIds.length > 0;

  useEffect(() => {
    setPerPage(0);
    const contentHeight = contentRef?.current?.clientHeight || 0;
    const newPerPage = Math.floor(contentHeight / 28);
    setPerPage(newPerPage);
  }, [contentRef?.current?.clientHeight]);

  useEffect(() => {
    if (perPage) {
      setIsloading(true);
      const delayDebounce = setTimeout(() => {
        setIsloading(true);
        getAllChats({ search: searchQuery, page: 1, perPage })
          .then((resp: any) => {
            setChatHistory!(resp?.data || []);
            setIsloading(false);
            setPage(1 + 1);
            setLastPage(resp?.last_page);
          })
          .catch((err: any) => {
            setIsloading(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }, 1500);
      return () => clearTimeout(delayDebounce);
    }
  }, [searchQuery, perPage]);

  const loadMoreChats = () => {
    setIsloadingMore(true);
    getAllChats({ search: searchQuery, page: page, perPage })
      .then((newChats: any) => {
        setChatHistory!((prevChats: any) => [...prevChats, ...newChats?.data]);
        setPage(page + 1);
        setLastPage(newChats?.last_page);
      })
      .catch((error) => console.error("Error loading more chats:", error))
      .finally(() => setIsloadingMore(false));
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (page > lastPage) {
    } else if (searchQuery === '') {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      const scrollPositionFromBottom =
        scrollHeight - (scrollTop + clientHeight);
      const loadThreshold = 1;
      if (scrollPositionFromBottom <= loadThreshold && !isloadingMore) {
        loadMoreChats();
      }
    }
  };

  const handleSelectId = (id: number) => {
    setSelectedChatIds(prevSelectedChatIds => {
      if (prevSelectedChatIds.includes(id)) {
        return prevSelectedChatIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevSelectedChatIds, id];
      }
    });
  };

  const onSelectChat = (chatItem: IChat | undefined) => {
    setOpenHistory!(true);
    setSelectedItem(chatItem);
    onSelectChatItem?.(chatItem);
    if (width && width <= 768) {
      setTimeout(() => toggleSidebar(), 500);
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  const handleCancel = () => {
    setSelectedChatIds([]);
    setChatDelConfirm(false);
  }

  const handleSubmit = () => {
    setLoading(true);
    removeMultipleChat(selectedChatIds)
      .then(() => {
        const updatedChatHistory = chatHistoryPagination && chatHistoryPagination.filter(
          (chatItem) => !selectedChatIds.includes(chatItem.id)
        );
        setChatHistory!(updatedChatHistory);
        setLoading(false);
        setChatDelConfirm(false);
        setSelectedChatIds([]);
        if (page > lastPage) {                             
        }
        else{
          loadMoreChats();
        }
        if (selectedItem && selectedChatIds.includes(selectedItem.id)) {
          push(`/${RoutePaths.Chat}/${ChatRoute.New}`); // Redirect to the new chat page
        }
      })
      .catch((err) => {
        setLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  }

  return (
    <>
      <div className={styles.historyIconContainer} >
        <HistoryIcon />
        <span>History</span>
        <div className={styles.delChat}>
          <div onClick={toggleAccordion}>
            <DownArrow />
          </div>
          {showDelete && (
            <div data-testid='delete-icon' className={styles.deleteHistory} onClick={() => { setChatDelConfirm(true) }}>
              <DeleteHistory />
            </div>
          )}
        </div>
      </div>
      {chatDelConfirm && (
        <DeleteChatConfirm onCancel={handleCancel} onSubmit={handleSubmit} loading={loading} />
      )}

      <div className={styles.content} onScroll={handleScroll} ref={contentRef}>
        {isAccordionOpen && (
          <>
            {isloading && <LoadingPage smallLoader />}
            {!isloading && chatHistoryPagination?.length === 0 && (
              <div
                className={styles.emptySearchMessage}
              >
                <FormattedMessage id="sidebar.chat.noChat.message" />
              </div>
            )}

            {!isloading &&
              chatHistoryPagination &&
              chatHistoryPagination.length > 0 &&
              chatHistoryPagination.map((chatItem, key) =>
                searchQuery && searchQuery.length > 0 ? (
                  <ChatSearchItem
                    key={`topic-${key}`}
                    searchQuery={searchQuery}
                    chatItem={chatItem}
                    onSelectChat={onSelectChat}
                  />
                ) : (
                  <ChatItem
                    key={`topic-${key}`}
                    chatItem={chatItem}
                    selectedItem={selectedItem}
                    onSelectChat={onSelectChat}
                    searchQuery={searchQuery}
                    setChatHistory={setChatHistory || (() => { })}
                    chatHistoryPagination={chatHistoryPagination}
                    handleSelectId={handleSelectId}
                    selectedChatIds={selectedChatIds}
                  />
                )
              )}
            {isloadingMore && (
              <div className="flex items-center justify-center">
                <Spinner small />
              </div>
            )}
          </>
        )
        }
      </div>

    </>
  );
};