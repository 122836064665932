import React, { Dispatch, SetStateAction, memo } from "react";
import { useIntl } from "react-intl";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import classNames from "classnames";
import styles from "./SearchField.module.scss";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SpinAnimation } from "components/base/Button/LoadingButton";
import { useSelector } from "redux/hooks";
import { SendButton } from "../sendButton";
import { EChatType, IChat, IChatModel } from "redux/actions";
import { DocUpload } from "../DocumentUpload";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { FileUploadPreview } from "./FileUploadPreview";

interface IProps extends TextareaAutosizeProps {
  isloading?: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  isFieldFocused?: boolean;
  onTextareaFocus?: () => void;
  isFileUploading?: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  updateChatModel?: (modalType: EChatType) => void;
  selectedFile?: File[] | null;
  setSelectedFile?: Dispatch<SetStateAction<File[] | null>>;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setUploadUrl?: Dispatch<SetStateAction<boolean>>;
  resetMessage?: () => void;
  message?: string;
  url?: string;
  setURL?: Dispatch<SetStateAction<string>>;
  onSendMessage?: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
}

const Spinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 18px;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: ${({ theme }) => theme.primaryColor};

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const SearchField = memo(
  ({
    isloading,
    textareaRef,
    isFieldFocused,
    onTextareaFocus,
    isFileUploading,
    handleSubmit,
    updateChatModel,
    selectedFile,
    setSelectedFile,
    setChatHistory,
    chatHistoryPagination,
    setIsMainScreenOpen,
    setUploadUrl,
    resetMessage,
    message,
    url,
    setURL,
    onSendMessage,
    setOpenHistory,
    uploadingFiles,
    setUploadingFiles,
    setFileS3Link,
    setIsFileUploading,
    isAllowUploadFile,
    chatItem,
    ...props
  }: IProps) => {
    const { theme } = useSelector((state) => state.authReducer);
    const { formatMessage } = useIntl();

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (textareaRef.current) textareaRef.current.focus();
    };

    return (
      <div
        className={classNames(styles.textAreaContainer, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
          [styles.activeborder]: true,
          [styles.file]: uploadingFiles && uploadingFiles.length > 0,
        })}
        onClick={() => {
          if (textareaRef.current) textareaRef.current.focus();
          onTextareaFocus?.();
        }}
      >
        <FileUploadPreview
          uploadingFiles={uploadingFiles}
          selectedFile={selectedFile}
          setUploadingFiles={setUploadingFiles}
          setFileS3Link={setFileS3Link}
        />

        <div className={`flex w-[100%] justify-center items-center`}>
          <DocUpload
            setSelectedFile={setSelectedFile}
            chatHistoryPagination={chatHistoryPagination}
            setChatHistory={setChatHistory}
            setIsMainScreenOpen={setIsMainScreenOpen}
            isFileUploading={isFileUploading}
            selectedFile={selectedFile}
            setUploadUrl={setUploadUrl}
            resetMessage={resetMessage}
            message={message}
            url={url}
            setURL={setURL}
            onSendMessage={onSendMessage}
            setOpenHistory={setOpenHistory}
            setUploadingFiles={setUploadingFiles}
            setFileS3Link={setFileS3Link}
            setIsFileUploading={setIsFileUploading}
            uploadingFiles={uploadingFiles}
            isAllowUploadFile={isAllowUploadFile}
            chatItem={ chatItem}
          />

          <TextareaAutosize
            ref={textareaRef}
            className={classNames(styles.textarea, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
            minRows={1}
            maxRows={25}
            placeholder={formatMessage({
              id: "chatpage.searchField.placeholder",
            })}
            {...props}
          />
          {isloading && (
            <div className={styles.loaderContainer}>
              <Spinner icon={faCircleNotch} />
            </div>
          )}
          <div className={styles.chatfooterbtn}>
            <SendButton
              isFieldFocused={true}
              disabled={isFileUploading}
              data-testid="chat-footer-btn"
              onMouseDown={handleMouseDown}
              onClick={(event) => handleSubmit && handleSubmit(event)}
            />
          </div>
        </div>
      </div>
    );
  }
);
