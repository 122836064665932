import React from "react";
import classNames from "classnames";
import styles from "./answerContainer.module.scss";
import { DocumentIcon } from "../icons/DocumentIcon";
import { useSelector } from "redux/hooks";
import { IFile } from "redux/actions";
import { Tooltip } from "components";

interface IFileLinkProps {
    file: IFile[];
    contentFile?: boolean;
}

const FileLink: React.FC<IFileLinkProps> = ({ file, contentFile }) => {
    const { theme } = useSelector((state) => state.authReducer);                                   
    return (
        <div 
        className={classNames(styles.fileMessageContainer, {
            // [styles.docPadding]: contentFile,
        })}
        >
            <div
                className={classNames(styles.fileText, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.docPadding]: contentFile
                })}
                style={{ display: 'flex', marginTop: contentFile ? '7px' : '', gap: '20px' , overflowX:'auto'}}
            >
                  <div className={classNames(styles.imagesContainer,{
                    // [styles.docPadding]: contentFile
                  })}>
                {file && file.map((file) => {
                    const extractFileName = (path: string) => {
                        const decodedPath = decodeURIComponent(path);
                        const parts = decodedPath.split('/');
                        const lastSegment = parts[parts.length - 1];
                        const firstHyphenIndex = lastSegment.indexOf('-');
                        const secondHyphenIndex = lastSegment.indexOf('-', firstHyphenIndex + 1);
                        return secondHyphenIndex !== -1 ? lastSegment.substring(secondHyphenIndex + 1) : lastSegment;
                    };

                    const fileName = extractFileName(file?.path as string);
                    return (
                        <Tooltip
                            control={
                                <a href={file?.path as string} target="_blank"
                                    rel="noreferrer"
                                    className="mt-3 w-[20px]">
                                    <div
                                        className={classNames(styles.answerFile, {
                                            [styles.light]: theme === "light",
                                            [styles.dark]: theme === "dark",
                                        })}
                                    >
                                        {fileName}
                                    </div>
                                    <div className="mt-3">
                                        <DocumentIcon />
                                    </div>
                                </a>
                            }
                            placement="top"
                            theme="light"
                        >{fileName}</Tooltip>
                    )
                })}
                </div>
            </div>
        </div>
    );
};

export default FileLink;
