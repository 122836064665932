

const ChatEditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M13.7471 20.4438H20.9997" stroke="#8044DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z" stroke="#8044DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.0205 6L16.4728 10.1871" stroke="#8044DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    //     <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M9.492 0.789062H5.753C2.678 0.789062 0.75 2.96606 0.75 6.04806V14.3621C0.75 17.4441 2.669 19.6211 5.753 19.6211H14.577C17.662 19.6211 19.581 17.4441 19.581 14.3621V10.3341" stroke="#8044DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    // <path fillRule="evenodd" clipRule="evenodd" d="M6.82666 8.9205L14.2997 1.4475C15.2307 0.5175 16.7397 0.5175 17.6707 1.4475L18.8877 2.6645C19.8187 3.5955 19.8187 5.1055 18.8877 6.0355L11.3787 13.5445C10.9717 13.9515 10.4197 14.1805 9.84366 14.1805H6.09766L6.19166 10.4005C6.20566 9.8445 6.43266 9.3145 6.82666 8.9205Z" stroke="#8044DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    // <path d="M13.1641 2.60156L17.7301 7.16756" stroke="#8044DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>

  )
}

export default ChatEditIcon