interface IProps {
  color?: string;
  chatList?: boolean
  
}

export const ImageIcon = ({ color = "#FFFFFF" , chatList}: IProps) => {
  return (
    <svg
      width={chatList? "16" : "22"}
      height={chatList? "16" : "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
          d="M4.125 19.25C3.75833 19.25 3.4375 19.1125 3.1625 18.8375C2.8875 18.5625 2.75 18.2417 2.75 17.875V4.125C2.75 3.75833 2.8875 3.4375 3.1625 3.1625C3.4375 2.8875 3.75833 2.75 4.125 2.75H17.875C18.2417 2.75 18.5625 2.8875 18.8375 3.1625C19.1125 3.4375 19.25 3.75833 19.25 4.125V17.875C19.25 18.2417 19.1125 18.5625 18.8375 18.8375C18.5625 19.1125 18.2417 19.25 17.875 19.25H4.125ZM4.125 17.875H17.875V4.125H4.125V17.875ZM5.40833 15.6521H16.6146L13.2458 11.1604L10.2208 15.0792L8.08958 12.1687L5.40833 15.6521Z"
          fill={color}
        />
    </svg>
  );
};
