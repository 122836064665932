import styles from "./HistoryDD.module.scss";
import classNames from "classnames";

import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";

import { HistoryIcon } from "../../../icons";
import {
  ImageIcon,
  ChatIcon,
  DocumentIcon,
} from "views/layout/Sidebar/component/ChatSidebar/components/icons";

import useRouter from "hooks/useRouter";

import { useSelector } from "redux/hooks";
import { IChat } from "redux/actions";
import { theme } from "theme";

interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined) => void;
}

export const HistoryDD = ({ onSelectChatItem }: IProps) => {
  const { pathname } = useRouter();
  const themeMode = useSelector((state) => state.authReducer.theme);
  const chats = useSelector((state) => state.chatReducer.chats);

  const chatId = pathname.split("/")[3];

  if (chats.length === 0) {
    return (
      <div>
        <HistoryIcon />
      </div>
    );
  }

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu
          style={{
            width: "263px",
            height: "auto",
            maxHeight: "169px",
            borderRadius: "20px",
            border: "1px solid rgba(255, 255, 255, 0.05)",
            backgroundColor:
              themeMode === "dark"
                ? "rgba(255, 255, 255, 0.03)"
                : "rgba(42, 40, 49, 0.03)",
            backdropFilter: "blur(25px)",
            boxShadow: "none",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div className={styles.menuContainer} />
          <div
            className={classNames(styles.overflowContainer, {
              [styles.light]: themeMode === "light",
              [styles.dark]: themeMode === "dark",
            })}
          >
            {chats.map(
              (chat) =>
                chat.topic && (
                  <MenuItem
                    className={classNames(styles.menuItem, {
                      [styles.light]: themeMode === "light",
                      [styles.dark]: themeMode === "dark",
                      [styles.isSelected]:
                        chat.id === (chatId ? JSON.parse(chatId) : -1),
                    })}
                    onClick={() => onSelectChatItem?.(chat)}
                    key={`${chat.id}`}
                  >
                    {chat.chat_type === "image" && (
                      <div
                        className={classNames(styles.icon, {
                          [styles.imageIcon]: chat.chat_type === "image",
                        })}
                      >
                        <ImageIcon color="#FFA439" />
                      </div>
                    )}
                    {chat.chat_type === "text" && (
                      <div className={styles.icon}>
                        <ChatIcon color={theme.primaryColor} />
                      </div>
                    )}
                    {chat.chat_type === "document" && (
                      <div className={styles.icon}>
                        <DocumentIcon color="#2DBF3C" />
                      </div>
                    )}
                    <div className={styles.text}>{chat.topic}</div>
                  </MenuItem>
                )
            )}
          </div>
        </Menu>
      }
      overlayStyle={{ zIndex: 10003 }}
    >
      <div>
        <HistoryIcon />
      </div>
    </Dropdown>
  );
};
