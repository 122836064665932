import { EThemeType } from "redux/reducers";
import styled from "styled-components";

interface IIconContainer {
  variant?: EThemeType;
}

export const FeatureCard = styled.div<{
  themeMode?: EThemeType;
  isBackground?: boolean;
  customerPortal?: boolean;
  chatSetting?: boolean;
  packageinfo?: boolean;
  buycredit?: boolean;
}>`
  width: 100%;
  background-color: ${({ themeMode, isBackground, chatSetting, }) =>
    chatSetting && themeMode === 'light' ? ' rgba(124, 77, 255, 0.06)' :
      chatSetting && themeMode === 'dark' ? ' rgba(182, 156, 255, 0.06)' :
        isBackground
          ? themeMode === "dark"
            ? "rgba(255, 255, 255, 0.06)"
            : "#FFF"
          : "transparent"};
  border-radius: ${({ chatSetting }) => chatSetting ? '10px' : '24px'};
  padding: ${({ customerPortal, chatSetting }) =>
    customerPortal ? '25px' : chatSetting ? '10px' : '16px'};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  color: ${({ themeMode, theme, chatSetting }) =>
    chatSetting && themeMode === "light" ? theme.black : chatSetting && themeMode === "dark" ? '#ffffff' : themeMode === "light" ? theme.textColorLight : theme.white};

  @media (max-width: 576px) {
    gap: 8px;
  }

  @media (max-width: 375px)
  {
    flex-direction: ${({ packageinfo }) => packageinfo ? 'column' : 'row'}
  }
  @media (min-width: 1130px){
    background-color: ${({ themeMode, buycredit }) =>
    buycredit && themeMode === "light" ? '#f6f6f7' : buycredit && themeMode === "dark" ? 'rgba(255, 255, 255, 0.06)' : ''
  }
`;

export const FeaturesmallCard = styled.div<{
  themeMode?: EThemeType;
  isBackground?: boolean;
}>`
  width: 100%;
  background-color: ${({ themeMode, isBackground }) =>
    isBackground
      ? themeMode === "dark"
        ? "rgba(255, 255, 255, 0.06)"
        : "#fff"
      : "transparent"};
  border-radius: 24px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  color: ${({ themeMode, theme }) =>
    themeMode === "light" ? theme.textColorLight : theme.white};

  @media (max-width: 576px) {
    gap: 8px;
  }
`;

export const IconContainer = styled.div<IIconContainer>`
  // background: ${({ variant }) => variant === "light" ? "transparent" : "rgba(255, 255, 255, 0.03)"};
  border: ${({ variant }) =>
    `1px solid ${variant === "light" ? "rgba(35, 37, 39, 0.04)" : "#fff"}`};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  @media (max-width: 386px)
  {
    padding:5px;
  }
`;

export const Icon = styled.div<IIconContainer>`
  svg {
    & path {
      stroke: ${({ variant }) => (variant === "light" ? "#2A2831" : "#FFFFFF")};
    }
  }
`;
