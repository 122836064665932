import { FormattedMessage } from "react-intl";
import { Dispatch, SetStateAction } from "react";
import styles from "./Prompt.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";

interface ITab {
    label: string;
    value: string;
}

interface IProps {
    data: ITab[];
    activeTab: any;
    setActiveTab: Dispatch<SetStateAction<string>>;
}


export const PromptOptions = ({ data, activeTab, setActiveTab }: IProps) => {
    const { theme } = useSelector((state) => state.authReducer);

    return (
        <div className="flex gap-4">
            {data.map(({ label, value }) => (
                <button
                    key={value}
                    data-testid={value==='myprompts'? 'user-prompts-btn': 'community-prompts-btn'}
                    onClick={() => setActiveTab(value)}
                    className={`font-bold text-lg bg-transparent ${activeTab === value ? "text-primary " + classNames(styles.activeborder) : "border-transparent"} ${theme === 'dark' && activeTab !== value ? "text-white" : ""}`}
                    style={{borderBottom: activeTab!== value && theme==='dark'?  '2px solid white': activeTab!== value && theme==='light'? '2px solid #2a2831':'' }}
                >
                    <FormattedMessage id={label} />
                </button>
            ))}
        </div>
    )
};