export const CopyIcon = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 19.1438C1.75833 19.1438 1.4375 19.0063 1.1625 18.7313C0.8875 18.4563 0.75 18.1354 0.75 17.7688V3.95001H2.125V17.7688H12.9875V19.1438H2.125ZM4.875 16.3938C4.50833 16.3938 4.1875 16.2563 3.9125 15.9813C3.6375 15.7063 3.5 15.3854 3.5 15.0188V2.18542C3.5 1.81876 3.6375 1.49792 3.9125 1.22292C4.1875 0.947925 4.50833 0.810425 4.875 0.810425H14.9583C15.325 0.810425 15.6458 0.947925 15.9208 1.22292C16.1958 1.49792 16.3333 1.81876 16.3333 2.18542V15.0188C16.3333 15.3854 16.1958 15.7063 15.9208 15.9813C15.6458 16.2563 15.325 16.3938 14.9583 16.3938H4.875ZM4.875 15.0188H14.9583V2.18542H4.875V15.0188Z"
        fill="white"
      />
    </svg>
  );
};
