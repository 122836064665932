import React, {
  memo,
  Fragment,
  useState,
  useEffect,
  useRef,
} from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./answerContainer.module.scss";

import {
  // DocIcon,
  CopyIcon,
  ShareIcon,
  RegenerateIcon,
} from "../icons";
import { AssistanceIcon } from "../userName";

import { Tooltip } from "components";
import { Message } from "../message";
import { DocQuestion } from "../docQuestion";

import {
  IChat,
  IChatModel,
  IMessage,
  IShareChatMessage,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import { RegenerateWithModel } from "../regeneratewithModel";
// import { DocumentIcon } from "../icons/DocumentIcon";
// import messageStyles from "../message/message.module.scss";

interface IProps {
  isAllChunksReceived?: boolean;
  message?: IMessage;
  isGenerating?: boolean;
  onSendMessage?: (question: string) => void;
  onComplete?: () => void;
  onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
  share?: boolean;
  toggleShareChat?: () => void;
  shareMessage?: IShareChatMessage;
  shareChat?: boolean;
  handleCheckboxChange?: (messageId: any) => void;
  chatItem?: IChat;
  searchQuery?: string;
  setHighlighted?: React.Dispatch<React.SetStateAction<boolean>>;
  highlighted?: boolean;
  setContainerHeight?: React.Dispatch<React.SetStateAction<number>>;
  messageIndex?: number;
  userMessage?: boolean;
}

export const BlockType = {
  text: "text",
  code: "code",
} as const;

export type EBlockType = (typeof BlockType)[keyof typeof BlockType];

export interface IMessageBlock {
  blockType: EBlockType;
  language?: string;
  message?: string;
}

export const Answer = memo(
  ({
    isAllChunksReceived,
    message,
    isGenerating,
    onSendMessage,
    onComplete,
    onRegenerate,
    share,
    toggleShareChat,
    shareMessage,
    shareChat,
    handleCheckboxChange,
    chatItem,
    searchQuery,
    highlighted,
    setContainerHeight,
    messageIndex,
    // userMessage,
  }: IProps) => {
    const { theme } = useSelector((state) => state.authReducer);

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const isHighlighted = searchQuery !== "";

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const updateContainerHeight = () => {
        if (containerRef.current) {
          const height = containerRef.current.getBoundingClientRect().height;
          setContainerHeight && setContainerHeight(height);
        }
      };
      updateContainerHeight();
      window.addEventListener("resize", updateContainerHeight);
      return () => {
        window.removeEventListener("resize", updateContainerHeight);
      };
    }, [message]);

    useEffect(() => {
      const intervalID = setTimeout(() => {
        if (isCopied) setIsCopied(false);
      }, 500);

      return () => clearInterval(intervalID);
    }, [isCopied]);

    const content = String(message?.content);
    const [typingFinished, setTypingFinished] = useState(false);

    const showAssistanAction = message?.isNew
      ? content.length > 0 && !isGenerating && !message?.files
      : content.length > 0;

    useEffect(() => {
      if (isAllChunksReceived) setTypingFinished(true);
    }, [isAllChunksReceived]);

    useEffect(() => {
      if (typingFinished) {
        onComplete?.();
      }
    }, [typingFinished]);

    useEffect(() => {
      if (!isGenerating && (content as string).length > 100)
        setTypingFinished(true);
    }, [isGenerating, content]);

    const handleCopy = (e: any) => {
      e.preventDefault();

      const selection = window.getSelection();
      let messageText = selection ? selection.toString() : "";
      message?.related_questions?.forEach((question) => {
        messageText = messageText.replace(question, "");
      });
      messageText = messageText.replace(/\s{2,}/g, " ");

      e.clipboardData.setData("text/plain", messageText);
      setIsCopied(true);
    };

    return (
      <Fragment>
        <div className={styles.container} ref={containerRef}>
          <div className={styles.leftContainer}>
            <div
              className={classNames({
                [styles.assistanceIconAnimation]:
                  (message?.isNew && !content) ||
                  (message?.isNew && content && isGenerating),
              })}
            >
              <AssistanceIcon />
            </div>
            <div
              className={styles.messageContainer}
              onDoubleClick={(e) => {
                e.stopPropagation();
              }}
              onCopy={handleCopy}
            >
              {/* {(message?.file && !userMessage)  && (
                  <div
                    className={classNames(styles.fileText, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <a
                      href={message.file.path as string}
                      target="_blank"
                      rel="noreferrer"
                      className="mt-3"
                    >
                      <div
                        className={classNames(styles.answerFile, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                        })}
                      >
                        {" "}
                        {message.file.name}{" "}
                      </div>
                      <div className="mt-3">
                      <DocumentIcon />
                      </div>
                    </a>
                  </div>
                )} */}
              <Message
                message={message}
                shareMessage={shareMessage}
                searchQuery={searchQuery}
                isHighlighted={isHighlighted}
                highlighted={highlighted}
              />
            </div>
          </div>
        </div>
        {!shareChat && showAssistanAction && (
          <div className={styles.actionContainer}>
            {/* COPY BUTTON */}
            <Tooltip
              control={
                <CopyToClipboard
                  text={content}
                  onCopy={() => setIsCopied(true)}
                >
                  <div
                    className={classNames(styles.icon, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <CopyIcon />
                  </div>
                </CopyToClipboard>
              }
              placement="top"
              theme="light"
            >
              <div>
                <FormattedMessage
                  id={
                    isCopied ? "answer.tooltip.copied" : "answer.tooltip.copy"
                  }
                />
              </div>
            </Tooltip>
            {/* {!userMessage && ( */}
              <>
                {/* REGENERATE BUTTON */}
                <Tooltip
                  control={
                    <div
                      className={classNames(styles.icon, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                      onClick={() =>
                        onRegenerate &&
                        onRegenerate(
                          messageIndex ? messageIndex : 0,
                          {} as IChatModel
                        )
                      }
                      data-testid="regenerate-answer-btn"
                    >
                      <RegenerateIcon />
                    </div>
                  }
                  placement="top"
                  theme="light"
                >
                  <div>
                    <FormattedMessage id="answer.tooltip.regenerate" />
                  </div>
                </Tooltip>

                {/* Regenerate with Model */}
                <RegenerateWithModel
                  message={message}
                  chatItem={chatItem}
                  messageIndex={messageIndex}
                  onRegenerate={onRegenerate}
                />

                {/* SHARE BUTTON */}
                {!share && (
                  <Tooltip
                    control={
                      <div
                        className={classNames(styles.icon, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                        })}
                        onClick={() => {
                          toggleShareChat!();
                          handleCheckboxChange!(message?.id);
                        }}
                        style={{ marginLeft: "-12px" }}
                        data-testid="share-chat-btn"
                      >
                        <ShareIcon />
                      </div>
                    }
                    placement="top"
                    theme="light"
                  >
                    <div>
                      <FormattedMessage id="share.tooltip.chat" />
                    </div>
                  </Tooltip>
                )}
              </>
            {/* )} */}
          </div>
        )}
        {!shareChat &&
          !isGenerating &&
          (message?.related_questions as string[])?.length > 0 && (
            <div className={styles.questionContainer}>
              {message?.related_questions?.map((question, index) => (
                <DocQuestion
                  key={`${message.id}-${index}`}
                  question={question}
                  onClick={() => onSendMessage?.(question)}
                  delay={500 * index}
                />
              ))}
            </div>
          )}
      </Fragment>
    );
  }
);
