import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import classNames from "classnames";
import styles from "./TeamTable.module.scss";

import { UserRole } from "../UserRole/UserRole";
import { ActionsMenu, IActionProps } from "../ActionMenu";
import { LoadingPage } from "components";

import { IMember, UserRoles, UserRolesType } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps extends IActionProps {
  members: IMember[];
  invitations: IMember[];
  isloading?: boolean;
}

export const TeamTable = (props: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  const allowActions = (): UserRolesType[] => {
    const { userRole } = props;
    if (userRole === "admin") {
      return [UserRoles.manager, UserRoles.member];
    } else if (userRole === "manager") {
      return [UserRoles.member];
    } else {
      return [];
    }
  };

  if (props.isloading) return <LoadingPage />;
  return (
    
    <table
      className={classNames(styles.teamTable, {
        [styles.teamTableLight]: theme === "light",
        [styles.teamTableDark]: theme === "dark",
      })}
    >
      <thead>
        <tr 
        className={styles.smallscreen}
        >
          <th>
            <FormattedMessage id="team.table.th.name" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.email" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.date" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.status" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.creditsToday" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.dailyCredits" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.totalUsed" />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.members.map((member) => (
          <tr>
            <td>
              <div className={styles.name}>{member.name ?? "_"}</div>
              <div className={styles.firstChild}>
                <UserRole type={member.role} />
                <div>{member.name ?? "_"}</div>
                <div>{member.email}</div>
                <div>{moment.utc(member.created_at).format("MM.DD.YYYY")}</div>
              </div>
            </td>
            <td>{member.email}</td>
            <td>{moment.utc(member.created_at).format("MM.DD.YYYY")}</td>
            <td>
              <UserRole type={member.role} />
            </td>
            <td>{member.role === "admin" ? "_" : member.used_today ?? 0}</td>
            <td>{member.role === "admin" ? "_" : member.daily_limit ?? 0}</td>
            <td>{member.total_used_monthly ?? 0}</td>
            <td>
              {allowActions().includes(member.role) && (
                <ActionsMenu isInvited={false} {...props} member={member} />
              )}
            </td>
          </tr>
        ))}
        {props.invitations.length > 0 && (
          <Fragment>
            <div className={styles.separator} />
            <tr style={{ borderBottom: "none" }}>
              <td colSpan={7}>
                <div
                  className={classNames(styles.pendingHeading, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                >
                  <FormattedMessage id="invite.teamMember.pending" />
                </div>
              </td>
            </tr>
            <div className={styles.separator} />
          </Fragment>
        )}
        {props.invitations.map((member) => (
          <tr>
            <td>
              <div className={styles.name}>{member.name ?? "_"}</div>
              <div className={styles.firstChild}>
                <UserRole type={member.role} />
                <div>{member.name ?? "_"}</div>
                <div>{member.email}</div>
                <div>{moment.utc(member.created_at).format("MM.DD.YYYY")}</div>
              </div>
            </td>
            <td>{member.email}</td>
            <td>{moment.utc(member.created_at).format("MM.DD.YYYY")}</td>
            <td>
              <UserRole type={member.role} />
            </td>
            <td>{member.used_today ?? "_"}</td>
            <td>{member.daily_limit ?? "_"}</td>
            <td>{member.total_used_monthly ?? "_"}</td>
            <td>
              <ActionsMenu isInvited={true} {...props} member={member} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
