import { SystemLogo ,LightSysIcon, DarkSysIcon} from "../components/system_updateIcon";
import styles from "./system.module.scss";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";

export const System_Update = () => {

    const {push} = useRouter();
    const { theme } = useSelector((state) => state.authReducer);
    const userDetail = useSelector((state) => state.authReducer.userDetail);
  
    const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

    return (
        <>
            <div className={classNames(styles.main, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
            })}>
                <div className={styles.system}>
                    <div className={styles.Logo}  onClick={() => push(`/${lang}${RoutePaths.Root}`)}>
                        <SystemLogo />
                        <span className={styles.LogoHeading} >DeftGPT</span>
                    </div>
                    <div className={styles.headWrapper}>
                        <h2 className={classNames(styles.Heading, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}>System Update</h2>

                        <p className={classNames(styles.text, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}><FormattedMessage id="system" /></p>
                    </div>
                </div>
                <div className={classNames(styles.iconWrapper, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}>
                {theme === "light" ? <LightSysIcon /> : <DarkSysIcon />}
                </div>
            </div>
        </>
    )
}