import { useCallback } from "react";
import styles from "./ThemeSwitch.module.scss";
import classNames from "classnames";

import { DarkIcon, LightIcon } from "../Icons";

import { setTheme } from "redux/actions";
import { useSelector } from "redux/hooks";

export const ThemeSwitch = () => {
  const theme = useSelector((state) => state.authReducer.theme);

  const toggleTheme = useCallback(() => {
    if (theme === "dark") setTheme("light");
    else setTheme("dark");
  }, [theme]);

  return (
    <div
      className={classNames(styles.switchButton, {
        [styles.dark]: theme === "dark",
        [styles.light]: theme === "light",
      })}
      onClick={toggleTheme}
      data-testid='toggle-theme-change'
    >
      <div
        className={classNames(styles.state, {
          [styles.isActiveLight]: theme === "light",
        })}
      >
        <LightIcon />
      </div>
      <div
        className={classNames(styles.state, {
          [styles.isActiveDark]: theme === "dark",
        })}
      >
        <DarkIcon />
      </div>
    </div>
  );
};
