import { useState, useEffect, useCallback } from "react";
import { useEffectOnce } from "react-use";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./Account.module.scss";
import { CurrentPlanIcon } from "views/layout/Sidebar/component/SettingSidebar/components/icons";
import { OptionsIcon } from './icons/OptionsIcon';

import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { DropDown, DropDownRow, Separator } from "components";
import { UserName } from "pages/ChatPage/pages/chatHistory/components/userName";
import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { languages } from "utils/languages";


import {
  getActivePlan,
  getAllTeams,
  selectTeam,
  updateLanguage,
  getCreditLimits,
  removeAccount,
} from "redux/actions";
import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import { DeleteHistory } from "views/layout/Sidebar/component/ChatSidebar/components/icons/DeleteHistory";
import { DeleteChatConfirm } from "views/layout/Sidebar/component/ChatSidebar/components/DeleteChatConfirm";

export const Account = () => {
  const { triggerNotification } = useAppNotification();
  const { userDetail, creditLimits, theme } = useSelector(
    (state) => state.authReducer
  );
  const teams = useSelector((state) => state.teamReducer.teams);
  const { push } = useRouter();

  const [teamOptions, setTeamOptions] = useState<DropDownRow.IDataItem[]>([]);
  const [team, setTeam] = useState<any>();
  const [language, setLanguage] = useState<string | undefined>(
    languages[0].value
  );
  const [visible, setVisible] = useState(false);
  const [delAccount, setDelAccount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onGetPlan = () => {
    getActivePlan().catch((err: any) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  };

  const onGetTeams = () => {
    getAllTeams().catch((err: any) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  };

  useEffectOnce(() => {
    onGetPlan();
    onGetTeams();
  });

  useEffect(() => {
    setLanguage(userDetail?.user.language || languages[0].value);
    if (teams !== undefined && teams.length > 0) { 
      const isAvaliable = teams.findIndex(
        (team) => team.id === userDetail?.user.team.id
      );
      if (isAvaliable > -1) {
        setTeam(userDetail?.user.team.id);
      } else {
        const currentTeam = teams.find(
          (team) => team.id === userDetail?.user.activeSubscription.team_id
        );
        if (currentTeam) {
          setTeam(currentTeam.id);
          onSelectTeam(currentTeam.id);
        }
      }
    } 
  }, [userDetail, teams]);

  useEffect(() => {
    if (teams.length > 0) {
      setTeamOptions(
        teams.map((team) => {
          return {
            label: team.name,
            value: team.id,
          };
        })
      );
    }
  }, [teams]);

  const onSelectTeam = (team_id: number) => {
    setTeam(team_id);
    selectTeam({ team_id })
      .then(() => {
        getCreditLimits()
          .then(() => {
            setTeam(team_id);
          })
          .catch((err) => {
            setTeam(userDetail?.user.team.id);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      })
      .catch((err) => {
        setTeam(userDetail?.user.team.id);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  const onUpdateLanguage = useCallback((lang: string) => {
    setLanguage(lang);
    updateLanguage(lang);
  }, []);

  const onVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const handleDelAccount = () => {
    setLoading(true);
    removeAccount().then(() => {
      setLoading(false);
      setDelAccount(false)

    }).catch((err) => {
      setLoading(false);
      setDelAccount(false)
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.userInfo, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <UserName
          name={userDetail?.user?.name}
          email={userDetail?.user?.email}
          className={styles.userAvatar}
        />
        <span className={styles.userName}>{userDetail?.user?.name ?? ""}</span>
        <div className={styles.emailContainer}>
          <span className={styles.email}>{userDetail?.user?.email ?? ""}</span>
          <Dropdown

            trigger={['click']}
            onVisibleChange={onVisibleChange}
            visible={visible}
            overlay={
              <Menu
                style={{
                  width: "117px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: theme === "dark" ? "#252526" : "#ffffff",
                  backdropFilter: "blur(25px)",
                  overflow: "hidden",
                  position: "relative",
                  marginLeft: "20px",
                  marginTop: "-28px",
                }}
              >
                <div className={styles.DropDownbody}>
                  {/* <MenuItem key="edit" className={styles.menuItemEdit}>
                    <span style={{ marginLeft: "3px", marginTop: "4px" }}>
                      <EditIcon EditIcon={true} />
                    </span>
                    Edit Account
                  </MenuItem> */}
                  <MenuItem key="delete" className={styles.menuItemDel} onClick={() => setDelAccount(true)}>
                    <span style={{ marginTop: "2px" }}>
                      <DeleteHistory DeleteAccount={true} />
                    </span>
                    Delete Account
                  </MenuItem>
                </div>
              </Menu>}
          >
            <span className="cursor-pointer mt-[5px]">
              <OptionsIcon theme={theme==='dark'? true: false}/>
            </span>
          </Dropdown>
        </div>
        {delAccount && (
          <DeleteChatConfirm onCancel={() => setDelAccount(false)} onSubmit={handleDelAccount} loading={loading} delAccount={true}/>
        )}
        <Separator height="20px" />
        <div className={styles.teamDDContainer}>
          <DropDown
            $background={theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)"}
            $withBorder={false}
            options={teamOptions}
            value={team}
            placeholder={
              <FormattedMessage id="account.select.team.placeholder" />
            }
            onChange={(item: DropDownRow.IDataItem) => onSelectTeam(item.value)}
            className={styles.select}
            variant={theme}
            account={true}
          />
        </div>
      </div>
      <div className={styles.row}>
        <h4 className={classNames(styles.usageSmall, {
          [styles.lightss]: theme === "light",
          [styles.darkss]: theme === "dark",
        })}>
          <FormattedMessage id="settings.account.dailyUsage" />
        </h4>
        <div
          className={classNames(styles.col, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <div className={styles.usage}>
            <h4 className={styles.h4}>
              <FormattedMessage id="settings.account.dailyUsage" />
            </h4>
            <div className={styles.usageRow}>
              <span className={`opacity-80 ${styles.text}`}>
                <FormattedMessage id="settings.account.made" />
              </span>
              <span className={styles.number}>{creditLimits.used_today}</span>
            </div>
            <div className={styles.svg}>
            </div>
            <div className={styles.usageRow}>
              <span className={`opacity-80 ${styles.text}`}>
                {userDetail?.user.activeSubscription.name === "Free" ||
                  creditLimits.daily_limit ? (
                  <FormattedMessage id="settings.account.allowed.daily" />
                ) : (
                  <FormattedMessage id="settings.account.allowed.monthly" />
                )}
              </span>
              <span className={styles.number}>
                {creditLimits.daily_limit
                  ? creditLimits.daily_limit
                  : userDetail?.user.activeSubscription.total_credits ?? 0}
              </span>
            </div>
          </div>
        </div>
        <h4 className={classNames(styles.displaylang, {
          [styles.lightss]: theme === "light",
          [styles.darkss]: theme === "dark",
        })}>
          <FormattedMessage id="settings.account.languange" />
        </h4>
        <div
          className={classNames(styles.cols, {
            [styles.lights]: theme === "light",
            [styles.darks]: theme === "dark",
          })}
        >
          <div className={styles.language}>
            <h4 className={styles.h4}>
              <FormattedMessage id="settings.account.languange" />
            </h4>
            <DropDown
              $background={theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)"}
              variant={theme}
              options={languages}
              value={language}
              onChange={(item: DropDownRow.IDataItem) =>
                onUpdateLanguage(item.value)
              }
              height={44}
              className={styles.select}
              account={true}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.plan, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.planbox}>
          <span className={styles.text}>
            <FormattedMessage
              id={
                userDetail?.user.activeSubscription.name === "Free" ||
                  creditLimits.daily_limit
                  ? "settings.account.limited.perDay"
                  : "settings.account.limited.perMonth"
              }
              values={{
                credits: (
                  <span className={styles.count}>
                    {creditLimits.daily_limit
                      ? creditLimits.daily_limit
                      : userDetail?.user.activeSubscription.total_credits ?? 0}
                  </span>
                ),
              }}
            />
          </span>
        </div>
        <div className={styles.planTypeContainer}>
          <div className={styles.playType}>
            <span className={`font-bold opacity-90 ${styles.text}`}>
              <FormattedMessage id="settings.account.yourCurrentPlan" />
            </span>
            <span className={styles.planValue}>
              {userDetail?.user.activeSubscription.name}
            </span>
          </div>
          {userDetail?.user.team.role === "admin" && (
            <button
              data-testid='account-upgrade-plan'
              className={classNames(styles.upgradeButton, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
              onClick={() =>
                push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)
              }
            >
              <CurrentPlanIcon account={true} theme={theme} />
              <span>
                <FormattedMessage id="settings.account.upgrade" />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

