import { useState, useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, FieldProps, Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { theme, Theme } from "theme";

import { AuthContainer } from "../components/AuthContainer";
import { PageItem } from "./components/PageItem";
import {
  LabeledInput,
  Separator,
  LoadingButton,
  DropDown,
  DropDownRow,
} from "components";
import { FieldItem, Form } from "../components/FormComponents";
import { GoogleAuthBtn } from "../components/GoogleAuthBtn";
import { RoutePaths } from "../../routePaths";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { signup } from "redux/actions";

import { languages } from "utils/languages";
import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";

const Heading = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
`;

const Row = styled.div`
  width: 100%;
  padding: 0 6%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 30px;
  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    padding: 0 12%;
  }
  
`;

const LeftCol = styled.div`
  width: 550px;
  padding: 7% 0;
  @media screen and (max-width: 769px) {
    width: 100%;
    padding: 7% 0 3% 0;
    display: none;
  }
`;

const RightCol = styled.div`
  width: 550px;
  padding: 7% 0;
  @media screen and (max-width: 769px) {
    width: 100%;
    padding: 0 0 10% 0;
  }
`;

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 33px;
  @media screen and (max-width: 769px) {
    flex-direction: column;
    gap: 0;
  }
`;

const TextContainer = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LinksContainer = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.6;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};

  @media screen and (max-width: 769px) {
    display: none;
  }
`;

const LinksBottomContainer = styled.div`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  color: ${theme.darkTextColor6};
  margin-top: 20px;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const WarningLink = styled(Link)`
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const LinkText = styled(Link)`
  color: #8c49f7;
`;

const LoginPageValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .min(2, "signup.firstName.minLength")
    .required("signup.firstName.empty.error"),
  surname: yup
    .string()
    .min(2, "signup.surName.minLength")
    .required("signup.surName.empty.error"),
  email: yup
    .string()
    .email("signup.email.error")
    .required("signup.email.empty.error"),
});

export const SignupPage = () => {
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const themeMode = useSelector((state) => state.authReducer.theme);
  const lang = userDetail?.user?.language? userDetail?.user?.language: "en";

  const [language, setLanguage] = useState<string | undefined>(
    languages[0].value
  );

  useEffect(() => {
    setLanguage(userDetail?.user.language || languages[0].value);
  }, [userDetail]);

  return (
    <AuthContainer>
      <Row>
        <LeftCol>
          <Heading themeMode={themeMode}>
            <FormattedMessage id="signup.page.heading" />
          </Heading>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading1" />
          </PageItem>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading2" />
          </PageItem>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading3" />
          </PageItem>
          <Separator />
          <PageItem variant={themeMode}>
            <FormattedMessage id="signup.page.subHeading4" />
          </PageItem>
        </LeftCol>
        <RightCol>
          <GoogleAuthBtn themeMode={themeMode} dataTestID={'google-auth-signup-btn'}>
            <FormattedMessage id="signup.socialAuth.google.btn.text" />
          </GoogleAuthBtn>
          <Separator />
          <TextContainer themeMode={themeMode}>
            <FormattedMessage id="login.form.seperator.text" />
          </TextContainer>
          <Separator />
          <Formik
            initialValues={{
              first_name: "",
              surname: "",
              email: "",
            }}
            validationSchema={LoginPageValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (language) {
                ReactGA.event({
                  action: "WebsiteEmailSignupScreenSignupBtn_action",
                  category: "WebsiteEmailSignupScreenSignupBtn_category",
                  label: "WebsiteEmailSignupScreenSignupBtn_label",
                  value: 2,
                });

                signup({ ...values, language })
                  .then(() => {
                    push(`/${lang}/${RoutePaths.EmailSent}`);
                    setSubmitting(false);
                  })
                  .catch((err: any) => {
                    triggerNotification({
                      message: err?.data?.message,
                      type: "error",
                    });
                    setSubmitting(false);
                  });
              }
            }}
            validateOnBlur
            validateOnChange
          >
            {({ isSubmitting }) => (
              <Form data-testid='signup-form'>
                <FieldsRow>
                  <FieldItem bottom="17">
                    <Field name="first_name">
                      {({ field, meta }: FieldProps<string>) => (
                        <LabeledInput
                          {...field}
                          labelAdditionLength={0}
                          placeholder={formatMessage({
                            id: "signup.firstName.placeholder",
                          })}
                          type="text"
                          error={!!meta.error && meta.touched}
                          message={
                            meta.touched &&
                            meta.error &&
                            formatMessage({ id: meta.error })
                          }
                          variant={themeMode}
                          data-testid='signup-firstName'
                        />
                      )}
                    </Field>
                  </FieldItem>

                  <FieldItem bottom="17">
                    <Field name="surname">
                      {({ field, meta }: FieldProps<string>) => (
                        <LabeledInput
                          {...field}
                          labelAdditionLength={0}
                          placeholder={formatMessage({
                            id: "signup.surName.placeholder",
                          })}
                          type="text"
                          error={!!meta.error && meta.touched}
                          message={
                            meta.touched &&
                            meta.error &&
                            formatMessage({ id: meta.error })
                          }
                          variant={themeMode}
                          data-testid='signup-surName'
                        />
                      )}
                    </Field>
                  </FieldItem>
                </FieldsRow>
                <FieldItem bottom="17">
                  <Field name="email">
                    {({ field, meta }: FieldProps<string>) => (
                      <LabeledInput
                        {...field}
                        labelAdditionLength={0}
                        placeholder={formatMessage({
                          id: "signup.email.placeholder",
                        })}
                        type="text"
                        error={!!meta.error && meta.touched}
                        message={
                          meta.touched &&
                          meta.error &&
                          formatMessage({ id: meta.error })
                        }
                        variant={themeMode}
                        data-testid='signup-email'
                      />
                    )}
                  </Field>
                </FieldItem>
                <FieldItem bottom="17">
                  <DropDown
                    variant={themeMode}
                    height={40}
                    options={languages}
                    value={language}
                    onChange={(item: DropDownRow.IDataItem) =>
                      setLanguage(item.value)
                    }
                    data-testid='sign-up-lang-dropdown'
                  />
                </FieldItem>
                <LinksContainer themeMode={themeMode}>
                  <FormattedMessage id="signup.form.agree" />
                  &nbsp;
                  <WarningLink data-testid='signup-terms' to={`/${lang}/terms`}>
                    <FormattedMessage id="signup.form.termsOfService.link" />
                  </WarningLink>
                  &nbsp;
                  <FormattedMessage id="signup.form.and" />
                  &nbsp;
                  <WarningLink data-testid='signup-privacy' to={`/${lang}/privacy`}>
                    <FormattedMessage id="signup.form.privacyPolicy.link" />
                  </WarningLink>
                  &nbsp;
                  {/* <FormattedMessage id="signup.form.including" />
                  &nbsp;
                  <WarningLink to="#">
                    <FormattedMessage id="signup.form.cookieUse.link" />
                  </WarningLink> */}
                  .
                </LinksContainer>
                <Separator height="17px" />
                <LoadingButton
                  full
                  size="lg"
                  type="submit"
                  isloading={isSubmitting}
                  data-testid='signup-btn'
                >
                  <FormattedMessage id="signup.btn.text" />
                </LoadingButton>
              </Form>
            )}
          </Formik>
          <Separator />
          <TextContainer themeMode={themeMode}>
            <FormattedMessage id="signup.form.haveAccount" />
            &nbsp;&nbsp;
            <LinkText data-testid='signup-form-link' to={`/${lang}/${RoutePaths.Login}`}>
              <FormattedMessage id="signup.form.link" />
            </LinkText>
          </TextContainer>

          <LinksBottomContainer>
            <FormattedMessage id="signup.form.agree" />
            &nbsp;
            <WarningLink to="#">
              <FormattedMessage id="signup.form.termsOfService.link" />
            </WarningLink>
            &nbsp;
            <FormattedMessage id="signup.form.and" />
            &nbsp;
            <WarningLink to="#">
              <FormattedMessage id="signup.form.privacyPolicy.link" />
            </WarningLink>
            &nbsp;
            <FormattedMessage id="signup.form.including" />
            &nbsp;
            <WarningLink to="#">
              <FormattedMessage id="signup.form.cookieUse.link" />
            </WarningLink>
            .
          </LinksBottomContainer>
        </RightCol>
      </Row>
    </AuthContainer>
  );
};
