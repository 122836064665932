export const RegenerateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_5715_19396" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path d="M0 0H20V20H0V0Z" fill="white" />
      </mask>
        <path d="M20 1.4375C20.4142 1.4375 20.75 1.77329 20.75 2.1875C20.75 2.60171 20.4142 2.9375 20 2.9375V1.4375ZM14.5312 2.1875H13.7812V1.4375H14.5312V2.1875ZM20 2.9375H14.5312V1.4375H20V2.9375ZM15.2812 2.1875V7.65625H13.7812V2.1875H15.2812Z" fill="#8044DB" />
        <path d="M14.1553 2.83648C13.7969 2.62885 13.6747 2.16998 13.8823 1.81156C14.0899 1.45314 14.5488 1.3309 14.9072 1.53852L14.1553 2.83648ZM1.53125 10C1.53125 14.7635 5.23652 18.4688 10 18.4688V19.9688C4.40809 19.9688 0.03125 15.5919 0.03125 10H1.53125ZM10 18.4688C14.7635 18.4688 18.4688 14.7635 18.4688 10H19.9688C19.9688 15.5919 15.5919 19.9688 10 19.9688V18.4688ZM18.4688 10C18.4688 6.8388 16.7593 4.34493 14.1553 2.83648L14.9072 1.53852C17.902 3.27335 19.9688 6.22511 19.9688 10H18.4688Z" fill="#8044DB" />
        <path d="M10 0.03125C10.4142 0.03125 10.75 0.367037 10.75 0.78125C10.75 1.19546 10.4142 1.53125 10 1.53125V0.03125ZM0.03125 10C0.03125 9.40903 0.0802043 8.83073 0.17471 8.26899L1.65392 8.51786C1.57332 8.99694 1.53125 9.492 1.53125 10H0.03125ZM0.610348 6.58362C1.00523 5.46732 1.59031 4.44815 2.32925 3.56338L3.48053 4.52492C2.85537 5.27345 2.35957 6.13656 2.02448 7.08385L0.610348 6.58362ZM3.56339 2.32925C4.44815 1.59031 5.46732 1.00523 6.58362 0.610348L7.08386 2.02448C6.13656 2.35957 5.27345 2.85537 4.52492 3.48053L3.56339 2.32925ZM8.269 0.17471C8.83073 0.080204 9.40904 0.03125 10 0.03125V1.53125C9.492 1.53125 8.99694 1.57332 8.51786 1.65392L8.269 0.17471Z" fill="#8044DB" />
    </svg>
  );
};
