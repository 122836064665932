import { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./Team.module.scss";

import { TeamTable } from "./components/TeamTable";
import { ConfirmationModal } from "./components/ConfirmationModal";
import { ChangeRoleModal } from "./components/ChangeRoleModal";
import { CreditLimitModal } from "./components/CreditLimitModal";

import {
  getTeamMembers,
  IMember,
  removeTeamMember,
  updateMemberRole,
  updateMemberDailyLimit,
  UserRolesType,
  resendInvite,
  cancelInvite,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import { useAppNotification } from "hooks/services/AppNotification";

interface IProps {
  userRole?: UserRolesType;
}

export const Team = ({ userRole }: IProps) => {
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const { members, invitations } = useSelector((state) => state.teamReducer);
  const { formatMessage } = useIntl();
  const { triggerNotification } = useAppNotification();

  // Select Member
  const [member, setMember] = useState<IMember | undefined>(undefined);
  const onSelectMember = (selectedMember: IMember | undefined) =>
    setMember(selectedMember);

  // Confirmation Modal
  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<{
    isOpen?: boolean;
    heading?: string;
    message?: string;
    submitBtnText?: string;
    type: "DeleteMember" | "ResendInvite" | "CancelInvite";
  }>({
    isOpen: false,
    heading: "",
    message: "",
    submitBtnText: "",
    type: "DeleteMember",
  });
  const closeConfirmationModal = () => {
    setConfirmationModal({
      isOpen: false,
      heading: "",
      message: "",
      submitBtnText: "",
      type: "DeleteMember",
    });
    onSelectMember(undefined);
  };
  const onSubmitConfirmationModal = () => {
    if (confirmationModal.type === "DeleteMember") {
      onRemoveMember();
    } else if (confirmationModal.type === "ResendInvite") {
      onResendInvite();
    } else if (confirmationModal.type === "CancelInvite") {
      onCancelInvite();
    } else {
      return;
    }
  };

  // Load Team Members
  const [teamLoading, setTeamLoading] = useState<boolean>(false);
  const onGetTeamMembers = useCallback(() => {
    setTeamLoading(true);
    getTeamMembers()
      .then(() => {
        setTeamLoading(false);
      })
      .catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setTeamLoading(false);
      });
  }, []);
  useEffect(() => onGetTeamMembers(), [onGetTeamMembers]);

  // Update Member Role
  const [roleLoading, setRoleLoading] = useState<boolean>(false);
  const [changeStatusModal, setChangeRoleModal] = useState<boolean>(false);
  const toggleChangeRoleModal = () =>
    setChangeRoleModal((prev) => {
      if (!prev) {
        return true;
      } else {
        onSelectMember(undefined);
        return false;
      }
    });
  const onUpdateRole = (role?: UserRolesType) => {
    setRoleLoading(true);
    updateMemberRole({
      team_id: userDetail?.user.team.id,
      role: role,
      user_id: member?.id,
    })
      .then(() => {
        setRoleLoading(false);
        toggleChangeRoleModal();
      })
      .catch((err) => {
        setRoleLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  // Update Member Credit Limit
  const [creditLoading, setCreditLoading] = useState<boolean>(false);
  const [creditLimitModal, setCreditLimitModal] = useState<boolean>(false);
  const toggleCreditlimitModal = () => {
    setCreditLimitModal((prev) => {
      if (!prev) {
        return true;
      } else {
        onSelectMember(undefined);
        return false;
      }
    });
  };
  const onUpdateCredit = (credits: number) => {
    if (credits < 50) {
      triggerNotification({
        message: formatMessage(
          { id: "modal.creditLimit.min.error" },
          { credits: 50 }
        ),
        type: "error",
      });
    } else if (credits > Number(userDetail?.user.activeSubscription.credits)) {
      triggerNotification({
        message: formatMessage(
          { id: "modal.creditLimit.max.error" },
          { credits: userDetail?.user.activeSubscription.credits }
        ),
        type: "error",
      });
    } else {
      setCreditLoading(true);
      updateMemberDailyLimit({
        user_id: member?.id,
        team_id: userDetail?.user.team.id,
        credits: credits,
      })
        .then(() => {
          setCreditLoading(false);
          toggleCreditlimitModal();
        })
        .catch((err) => {
          setCreditLoading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    }
  };

  // Delete Team Member
  const onRemoveMember = () => {
    setConfirmationLoading(true);
    removeTeamMember({
      user_id: member?.id,
      team_id: userDetail?.user.team.id,
    })
      .then(() => {
        setConfirmationLoading(false);
        closeConfirmationModal();
      })
      .catch((err) => {
        setConfirmationLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  // Resend Invite Team Member
  const onResendInvite = () => {
    setConfirmationLoading(true);
    resendInvite({ invitation_id: member?.id })
      .then((res: any) => {
        setConfirmationLoading(false);
        closeConfirmationModal();
        triggerNotification({ message: res.data?.message, type: "info" });
        triggerNotification({
          message: formatMessage({ id: "modal.resendInvite.message.success" }),
          type: "info",
        });
      })
      .catch((err) => {
        setConfirmationLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  // Cancel Invite Team Member
  const onCancelInvite = () => {
    setConfirmationLoading(true);
    cancelInvite({ invitation_id: member?.id })
      .then(() => {
        setConfirmationLoading(false);
        closeConfirmationModal();
      })
      .catch((err) => {
        setConfirmationLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  return (
    <>
      <div
        className={classNames(styles.tableContainer, {
          [styles.isLoading]: teamLoading,
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <TeamTable
          userRole={userRole}
          isloading={teamLoading}
          members={members}
          invitations={invitations}
          toggleChangeRoleModal={toggleChangeRoleModal}
          toggleCreditlimitModal={toggleCreditlimitModal}
          toggleDeleteModal={() => {
            setConfirmationModal({
              isOpen: true,
              heading: "modal.deleteMember.heading",
              message: "modal.deleteMember.message",
              submitBtnText: "modal.deleteMember.btn.delete",
              type: "DeleteMember",
            });
          }}
          toggleResendInviteModal={() => {
            setConfirmationModal({
              isOpen: true,
              heading: "modal.resendInvite.heading",
              message: "modal.resendInvite.message",
              submitBtnText: "modal.resendInvite.btn.resendInvite",
              type: "ResendInvite",
            });
          }}
          toggleCancelInviteModal={() => {
            setConfirmationModal({
              isOpen: true,
              heading: "modal.cancelInvite.heading",
              message: "modal.cancelInvite.message",
              submitBtnText: "modal.cancelInvite.btn.cancelInvitation",
              type: "CancelInvite",
            });
          }}
          onSelectMember={onSelectMember}
        />
      </div>
      {changeStatusModal && (
        <ChangeRoleModal
          userRole={userRole}
          member={member}
          onClose={toggleChangeRoleModal}
          onSubmit={onUpdateRole}
          submitLoading={roleLoading}
        />
      )}
      {creditLimitModal && (
        <CreditLimitModal
          member={member}
          onClose={toggleCreditlimitModal}
          onSubmit={onUpdateCredit}
          submitLoading={creditLoading}
        />
      )}
      {confirmationModal.isOpen && (
        <ConfirmationModal
          heading={confirmationModal.heading}
          message={confirmationModal.message}
          memberName={member?.name}
          onClose={closeConfirmationModal}
          submitBtnText={confirmationModal.submitBtnText}
          onSubmit={onSubmitConfirmationModal}
          submitLoading={confirmationLoading}
        />
      )}
    </>
  );
};
