import { InputHTMLAttributes, LegacyRef } from "react";
import { useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./SearchField.module.scss";

import { RemoveIcon } from "../icons";

import { useSelector } from "redux/hooks";
import { SearchIcon } from "../icons/SearchIcon";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: LegacyRef<HTMLInputElement>;
  onClear?: () => void;
  isPrompt?: boolean;
  chatsidebar?: boolean;
  prompt?: boolean;
  handleSerchFocus?: () => void;
  gptModal?: boolean;
}

export const SearchField = ({
  value,
  inputRef,
  onClear,
  isPrompt,
  chatsidebar,
  prompt,
  handleSerchFocus,
  gptModal,
  ...props
}: IProps) => {
  const { formatMessage } = useIntl();
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
        [styles.withFocus]: true && !chatsidebar && !gptModal,
        [styles.sidebarfield]: chatsidebar,
        [styles.gptSearchField]: theme==='dark' && gptModal,
      })}
      onClick={handleSerchFocus}
    >
      {!value && (
        <SearchIcon prompt={prompt && theme === "light" || gptModal} />
      )}
      <input
        {...props}
        ref={inputRef}
        value={value}
        className={classNames(styles.searchField, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
          [styles.sidebarfield]: chatsidebar,
          [styles.gptModal] : theme==='dark' && gptModal,
        })}
        placeholder={
          isPrompt
            ? formatMessage({
              id: "prompt.search",
            }) :
            gptModal
              ? formatMessage({
                id: "gptmodel.search.placeholder",
              })
              : formatMessage({
                id: "sidebar.chat.searchField.placeholder",
              })
        }
      />
      {value && (
        <button
          className={classNames(styles.removeBtn,
            { [styles.light]: prompt && theme === "light" || gptModal && theme==='light' }
          )}
          onClick={onClear}
          data-testid='search-clear-btn'
        >
          <RemoveIcon />
        </button>
      )}
    </div>
  );
};
