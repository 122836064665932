import { ButtonHTMLAttributes } from "react";

import classNames from "classnames";
import styles from "./SendButton.module.scss";

import { DeftIcon } from "./icons";

import { useSelector } from "redux/hooks";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isFieldFocused?: boolean;
}

export const SendButton = ({ isFieldFocused, disabled, ...props }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <button
      className={classNames(styles.button, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
        [styles.focused]: isFieldFocused,
        [styles.disabled]: disabled,
        [styles.darkModeFocused]: isFieldFocused && theme === "dark",
      })}
      {...props}
    >
      <DeftIcon />
    </button>
  );
};
