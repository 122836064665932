import { FormattedMessage } from "react-intl";
import styles from "./regeneratewithModel.module.scss";

import { useSelector } from "redux/hooks";

import { IChatModel } from "redux/actions";
import classNames from "classnames";

interface IProps {
    chatModel: IChatModel;
    onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
    messageIndex?: number;
}

export const ModalItem = ({
    chatModel,
    onRegenerate,
    messageIndex,
}: IProps) => {

    const { theme } = useSelector((state) => state.authReducer);

    const handleRegenerate = (model: IChatModel) => {
        onRegenerate && onRegenerate(messageIndex ? messageIndex : 0, model)
    }

    return (
        <div className={`${classNames(styles.container)} hover:rounded-md hover:cursor-pointer hover:text-primary hover:bg-[rgba(124,77,255,0.08)] px-[22px] py-1`}
            onClick={() => handleRegenerate(chatModel)}
            data-testid='select-modal'
        >
            <div className={styles.imagedropdownContainer}>
                <img src={chatModel.image_url} />
            </div>
            <div className={styles.textContainer}>
                <div className={classNames(styles.dropdownModalName, {
                    [styles.dark]: theme === "dark",
                })}>
                    {chatModel.name}
                </div>
                {(chatModel?.type?.includes("image") && !chatModel?.type?.includes("image_chat")) ? null : (
                    <div className={classNames(styles.modelCreditPerQuery, {
                        [styles.dark]: theme === "dark",
                    })} >
                        <FormattedMessage
                            id="gptModal.queryCredits"
                            values={{ credits: chatModel.credits_per_message }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
