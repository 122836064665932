export const DeftIcon = () => (
  // <svg
  //   width="32"
  //   height="32"
  //   viewBox="0 0 32 32"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     d="M11.2587 2.02789V3.36655"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M2.02734 11.2587H7.66083"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M3.61816 3.61719L6.26758 6.26662"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M20.7412 29.972V28.6334"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M29.9714 20.7412H25.7881"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M28.3957 28.3967L25.7881 25.7891"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M2.02789 20.7407H3.36654"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M11.2587 29.9723V24.3945"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M3.61816 28.3967L6.23969 25.7612"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M29.972 11.2587H28.6334"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M20.7412 2.02734V7.66088"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M28.3962 3.61719L25.7607 6.23874"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M16.0244 9.05762L22.9964 16.0297L16.0244 23.0018L9.05241 16.0297L16.0244 9.05762Z"
  //     stroke="white"
  //     strokeWidth="3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
    <path d="M25.0467 19.0829L15.9407 18.9986L14.1336 10.5985C13.8748 9.39482 15.1634 8.44597 16.2336 9.0523L31.9174 17.9302C32.8979 18.4849 32.8709 19.9079 31.8691 20.4331L15.9057 28.8047C14.8134 29.3769 13.5636 28.3933 13.8649 27.199L15.9375 18.997" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
