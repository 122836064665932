import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "redux/store";
import { EChatType, EMessageViewType, IImage } from "../chat";

export interface IGenerateImageBody {
  message: string;
  chatId: number | null;
  newChatBoolean: boolean;
  model: string;
  chatType: EChatType;
  imageCount: number;
  size: string;
  messageViewType: EMessageViewType;
}

export interface IUpscaleImageBody {
  messageViewType: EMessageViewType;
  message_id: number;
  chat_id: number;
  image_id: number;
}

export const generateImage = ({
  messageViewType,
  ...data
}: IGenerateImageBody) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/image/generate", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err?.data?.message === "Network Error") {
          reject(err);
        } else {
          reject(err);
          store.dispatch({
            type: TYPES.GENERATE_IMAGE_ERROR,
            payload: {
              message: err?.data?.message,
              messageViewType: messageViewType,
            },
          });
        }
      });
  });
};

export const upscaleImage = (data: IUpscaleImageBody) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/image/upscale", {
        chat_id: data.chat_id,
        image_id: data.image_id,
      })
      .then((res) => {
        resolve(res.data);
        const image: IImage = {
          ...res.data.image,
          upscaled_images: [],
        };
        store.dispatch({
          type: TYPES.UPSCALE_IMAGE,
          payload: {
            messageViewType: data.messageViewType,
            message_id: data.message_id,
            image: image,
            upscaledImage: {
              image_id: data.image_id,
              upscale_image_id: res.data.image.id,
            },
          },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setShowUpscaleModal = ({
  showUpscaleModal,
}: {
  showUpscaleModal: "show" | "hide";
}) => {
  store.dispatch({
    type: TYPES.SET_SHOW_UPSCALE_MODAL,
    payload: { showUpscaleModal },
  });
};
