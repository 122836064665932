import { useRef, useState } from "react";
import ReactGA from "react-ga4";

import {
  ISendMessageBody,
  updateCredits,
  updateCreditLimit,
  answerNewChatQuestion,
  answerRegularQuestion,
  getNewChatTopic,
  IUser,
  IChat,
  updatedRegularQuestion,
  updateNewRegularQuestion,
} from "redux/actions";
import { useSelector } from "redux/hooks";

export const useChatStream = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  let controllerRef = useRef<AbortController | null>();
  const [done, setDone] = useState<boolean | null>(null);
  const [chatHistoryPagination, setChatHistory] = useState<IChat[]>([]);
  let chatId = useRef<number>(0);

  const startStream = (data: ISendMessageBody) => {
    return new Promise(async (resolve, reject) => {
      if (data.chatType === "document") {
        ReactGA.event({
          action: "WebsiteMessageSentDocumentChat",
          category: "WebsiteMessageSentDocumentChat",
        });
      }
      try {
        controllerRef.current = new AbortController();

        let body: any;
        if (data.file_path && data.file_path.length > 0) {
          body = {
            message: data.message,
            newChatBoolean: data.newChatBoolean,
            chatId: data.chatId,
            model: data.model,
            chatType: data.chatType,
            file_path: data.file_path,
            regenerate: data.regenerate,
          };
        } else if (data.images){
          body = {
            message: data.message,
            newChatBoolean: data.newChatBoolean,
            chatId: data.chatId,
            model: data.model,
            chatType: data.chatType,
            images: data.images,
            regenerate: data.regenerate,
          };
        }
         else {
          body = {
            message: data.message,
            newChatBoolean: data.newChatBoolean,
            chatId: data.chatId,
            model: data.model,
            chatType: data.chatType,      
            regenerate: data.regenerate,
            
          };
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASEURL}/api/chat/message`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${userDetail?.token}`,
            },
            body: JSON.stringify(body),
            signal: controllerRef?.current.signal,
          }
        );

        const reader = response?.body?.getReader();
        const decoder = new TextDecoder();

        let i = 0;
        setDone(false);
        let content = '';
        let texts = '';
        let isJsonParsed = false;
        while (true) {
          const streamResult = await reader?.read();
          const decoderText = decoder.decode(streamResult?.value);
          content += decoder.decode(streamResult?.value || new Uint8Array(), { stream: !streamResult?.done });
         
         let  completeMessage = decoderText;
        //  let regenerate_model_id=null;
          while (!isJsonParsed) {
            let leftBracket = content.indexOf('{');
            let rightBracket = content.lastIndexOf('}');
            if (leftBracket === -1 || rightBracket === -1) { break; }
            if ((leftBracket !== -1 && rightBracket !== -1)) {
              const jsonDataString = content.substring(leftBracket, rightBracket + 1);
              const jsonData = JSON.parse(jsonDataString);
              if (jsonData.message) {
                texts = content.substring(rightBracket + 1);
                isJsonParsed = true;
              }
            }
            completeMessage = content.substring(leftBracket, rightBracket + 1);
            content = content.substring(rightBracket + 1);
          }
          
          if (streamResult?.done) {
            setDone(true);
            // controllerRef.current.abort();
            controllerRef.current = null;

            setTimeout(() => {
              if (data.newChatBoolean && chatId.current) {
                getNewChatTopic(chatId.current).then((resp: any) => {
                  const newChatHistory = [resp, ...chatHistoryPagination];
                  setChatHistory(newChatHistory);
                })
                  .catch((err: any) => console.error(err));
                chatId.current = 0;
              }
            }, 3000);
            resolve({ ...streamResult, chatId: chatId.current });
            break;
          }
          if (i === 0) {
            try {
              const jsonData = JSON.parse((completeMessage  && texts!=='')  ? completeMessage : decoderText)
              completeMessage='';
              if (
                jsonData &&
                jsonData.credits !== undefined &&
                jsonData.daily_limit !== undefined &&
                jsonData.used_today !== undefined
              ) {
                if (userDetail?.user.team.role === "admin") {
                  updateCredits(jsonData.credits);
                } else {
                  updateCreditLimit({
                    daily_limit: jsonData.daily_limit,
                    used_today: jsonData.used_today,
                  });
                }

                if (jsonData.chat && jsonData.message) {
                  chatId.current = jsonData.chat.id;

                  const images = data.images &&  data?.images.map((img) => {
                    return {
                      id: 1,
                      model_type: '',
                      model_id: 1,
                      name: null,
                      path:  img,
                      size: '',
                      created_at: '',
                      updated_at: '',
                    }
                  });

                  if (data.messageViewType === "new") {
                    answerNewChatQuestion({
                      type: "assistant",
                      content: "",
                      isNew: true,
                      chat_id: jsonData.chat.id,
                      id: jsonData.assistant_message.id,
                      images: [],
                      regenerate_model_id: jsonData?.assistant_message?.regenerate_model_id,
                    });
                    updateNewRegularQuestion({
                      type: "user",
                    //  content: jsonData.message.content,
                      chat_id: jsonData.chat.id,
                      id: jsonData.message.id,
                      images: data?.images? images? images :[] :  [],
                    })
                  } else {
                    answerRegularQuestion({
                      type: "assistant",
                      content: "",
                      isNew: true,
                      chat_id: jsonData.chat.id,
                      id: jsonData.assistant_message.id,
                      images: [],
                      regenerate_model_id: jsonData?.assistant_message?.regenerate_model_id,
                    });
                    updatedRegularQuestion({
                      type: "user",
                    //  content: jsonData.message.content,
                      chat_id: jsonData.chat.id,
                      id: jsonData.message.id,
                      images: data?.images? images? images :[] :  [],
                    })
                  }
                }
              } else {
                if (jsonData.message) {
                  if (data.messageViewType === "new") {
                    answerNewChatQuestion({
                      type: "assistant",
                      content: jsonData.message,
                      isNew: true,
                      images: [],
                    });
                  } else {
                    answerRegularQuestion({
                      type: "assistant",
                      content: jsonData.message,
                      isNew: true,
                      images: [],
                    });
                  }
                }
                // triggerNotification({ message: jsonData.message, type: "error" });
                reject(jsonData);
              }

              ReactGA.event({
                action: "WebsiteChatScreenAssistantResponseSuccess_action",
                category: (userDetail?.user as IUser).activeSubscription.name,
                label: new Date().toISOString(),
                value: 5,
              });

              ReactGA.event({
                action: "WebsiteChatScreenCreditUsed_action",
                category: "WebsiteChatScreenCreditUsed_category",
                label: new Date().toISOString(),
              });
            } catch (error: any) {
              console.error("Error parsing JSON:", error);
            }
          } else {

            if (data.messageViewType === "new") {
              answerNewChatQuestion({
                type: "assistant",
                content: texts ? texts + decoderText : decoderText,
                isNew: true,
                images: [],
              });
              texts = ''
            }
            else {
              answerRegularQuestion({
                type: "assistant",
                content: texts ? texts + decoderText : decoderText,
                isNew: true,
                images: [],
              });
              texts = ''
            }
          }
          i++;
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  return { done, setDone, startStream, controllerRef, chatHistoryPagination, setChatHistory };
};
