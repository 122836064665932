import { useState, useEffect, memo, SetStateAction, Dispatch } from "react";
import { CloseIcon } from "components/icons/CloseIcon";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import styles from "./SearchField.module.scss";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SpinAnimation } from "components/base/Button/LoadingButton";

import { Spinner, Tooltip } from "components";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { DocIcon } from "../icons/DocIcon";
import { useAppNotification } from "hooks/services/AppNotification";
import { DeleteS3Link } from "redux/actions";

interface IProps extends TextareaAutosizeProps {
  selectedFile?: File[] | null;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
}

const UploadSpinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 12px;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: ${({ theme }) => theme.primaryColor};

  @media (max-width: 576px) {
    font-size: 10px;
  }
`;

export const FileUploadPreview = memo(({
  // selectedFile,
  uploadingFiles,
  setUploadingFiles,
  setFileS3Link,
}: IProps) => {

  const { triggerNotification } = useAppNotification();
  const [previews, setPreviews] = useState<(string | null)[]>([]);
  const [loading, setLoading] = useState<boolean[]>([]);

  useEffect(() => {
    if (uploadingFiles) {
      const filePreviews = uploadingFiles.map((uploadFile) => {
        const file = uploadFile.file;
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          return new Promise<string | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
          });
        }
        return Promise.resolve(null);
      });

      Promise.all(filePreviews).then(setPreviews);
    }
  }, [uploadingFiles]);

  const handleDelete = (S3Link: string, index: number) => {
    setLoading(prev => {
      const newLoadingFiles = [...prev];
      newLoadingFiles[index] = true;
      return newLoadingFiles;
    });

    DeleteS3Link(S3Link).then(() => {
      setLoading(prev => {
        const newLoadingFiles = [...prev];
        newLoadingFiles.splice(index, 1);
        return newLoadingFiles;
      });
      setFileS3Link!(prev => prev.filter(link => link !== S3Link));
      setUploadingFiles!(prev => prev.filter((_, i) => i !== index));
    }).catch((err) => {
      setLoading(prev => {
        const newLoadingFiles = [...prev];
        newLoadingFiles.splice(index, 1);
        return newLoadingFiles;
      });
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  return (
    <>
      {uploadingFiles && uploadingFiles.length > 0 && (
        <div className={styles.fileContainer}>
          {(uploadingFiles as IUploadFile[]).map((fil, index) => (
            fil.status !== 'error' &&
            <Tooltip
              control={
                <div key={index} className={styles.fileDisplay}>
                  <div className={styles.fileIcon}>
                    {fil.status === 'validating' || fil.status === 'uploading' ?
                      <div className={styles.loader}>
                        <UploadSpinner icon={faCircleNotch} />
                      </div> :
                      fil.file.type.startsWith("image/") && previews[index] && fil.status === 'uploaded' ? (
                        <img src={previews[index] as string} alt="Uploaded Preview" />
                      ) : (
                        <DocIcon />
                      )}
                  </div>
                  <div className={styles.fileInfo}>
                    <div className={styles.fileName}>{fil.file.name}</div>
                    <div className={styles.fileType}>{fil.file.type.startsWith("image/") ? 'Image' : 'Document'}</div>
                  </div>
                  {fil.status === 'uploaded' && (
                    loading[index]  ?
                    <div className={styles.closeButtonLoader}>
                      <Spinner extraSmall />
                      </div> :
                      <div className={styles.closeButton} onClick={() => { handleDelete(fil?.S3Link ? fil?.S3Link : '', index) }}>
                        <CloseIcon closeDoc={true} />
                      </div>
                  )}

                </div>
              }
              placement="top"
              theme="light">{fil.file.name}</Tooltip>
          ))}
        </div>
      )}
    </>
  );
});