import { IUploadFile } from "pages/ChatPage";
import { IErrorMessage, IFileErrorState } from "pages/ChatPage/pages";
import { Dispatch, SetStateAction } from "react";
import { IChatModel } from "redux/actions";
import { EThemeType } from "redux/reducers";

interface IValidateFileExtension {
  file?: File;
  url?: string;
  types: string[];
}

export type EFileSizes = keyof typeof FileSizes;

export const FileSizes = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
  TB: 1024 * 1024 * 1024 * 1024,
} as const;

interface IValidateFileSize {
  file: File;
  sizeOptions: {
    size: number;
    unit: EFileSizes;
  };
}

interface ICalculateRenderSize {
  originalWidth: number;
  originalHeight: number;
  containerWidth: number;
  containerHeight: number;
}

interface Settings {
  real_time_results: boolean;
  related_questions: boolean;
  send_message_with_enter: boolean;
}

export const randomNumber = (): string => {
  const date = new Date();
  const randomNum = Math.floor(Math.random() * 1000);
  const randomNumberWithTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}${randomNum}`;
  return randomNumberWithTime;
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const validateFileType = ({
  file,
  url,
  types,
}: IValidateFileExtension): boolean => {
  let returnValue = false;
  if (file) {
    const ext = file?.name.split(".").pop()?.toLowerCase() as string;
    if (types.includes(ext)) returnValue = true;
  }

  if (url) {
    const ext = url.split(".").pop()?.toLowerCase() as string;
    if (types.includes(ext)) returnValue = true;
  }

  return returnValue;
};

export const validateFileSize = ({
  file,
  sizeOptions,
}: IValidateFileSize): boolean => {
  const { size, unit } = sizeOptions;
  if (file.size > size * FileSizes[unit]) {
    return false;
  }
  return true;
};

export const calculateRenderedSize = ({
  originalWidth,
  originalHeight,
  containerWidth,
  containerHeight,
}: ICalculateRenderSize) => {
  const aspectRatio = originalWidth / originalHeight;

  let renderedWidth = containerWidth;
  let renderedHeight = renderedWidth / aspectRatio;

  if (renderedHeight > containerHeight) {
    renderedHeight = containerHeight;
    renderedWidth = renderedHeight * aspectRatio;
  }

  return {
    width: renderedWidth,
    height: renderedHeight,
  };
};

export const getThemeClass = (
  baseStyle: string,
  theme: EThemeType | undefined,
  styles: { light: string; dark: string }
) => {
  return theme === "light"
    ? `${baseStyle} ${styles?.light}`
    : `${baseStyle} ${styles?.dark}`;
};

export const preprocessLaTeX = (content: string) => {
  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  const blockProcessedContent = content.replace(
    /\\\[(.*?)\\\]/g,
    (_, equation) => `$$${equation}$$`
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/g,
    (_, equation) => `$${equation}$`
  );
  return inlineProcessedContent;
};


export const calculateCredit = (gptModel: IChatModel, settings: Settings,
  //  validFile: number, isFileUploading: boolean,
    selectedFile: IUploadFile[] | undefined,
  url: string, isAnswerComplete: boolean | undefined) => {

  const creditsPerMessage = gptModel.credits_per_message;
  const realTimeResults = settings?.real_time_results;
  let credit = creditsPerMessage + (realTimeResults ? 1 : 0);
  const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
  const imgURL = ["png", "jpg", "jpeg", "webp", "gif"]
  const isFirstFileImage = selectedFile && selectedFile[0]?.file.type.startsWith("image/");

  // if (validFile > 0 && isFileUploading && isAnswerComplete === true) {
  //   credit += validFile;
  // } 
   if (selectedFile && selectedFile.length > 0 && isFirstFileImage && isAnswerComplete === true) {
    credit += selectedFile.length;
  }
  // else if (selectedFile && selectedFile.length > 0 && isAnswerComplete === true) {
  //   credit += (selectedFile.length * 5);
  // } 
  else if (imgURL.includes(urlExtension) && isAnswerComplete === true) {
    credit += (url ? 1 : 0);
  }
  //  else if (url && isAnswerComplete === true) {
  //   credit +=  (url ? 5 : 0) ;
  // }

  return credit;
};

export const ErrorMessages = (errorMessage: IErrorMessage[], setErrorModal: Dispatch<SetStateAction<IFileErrorState>>) => {
  const combinedErrorMessages = errorMessage && errorMessage
    .map((error) => ` ${error.error}`)
    .join('\n');

  if (errorMessage && errorMessage.length > 0) {
    setErrorModal({
      message: combinedErrorMessages ?? '', // Pass the combined error messages here
      show: true,
    });
  }
}

export const convertFileNamesToLowercase = (imageFiles: File[]): File[] => {
  return imageFiles.map(file => {
    const fileType = file.type.toLowerCase();
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.slice(0, lastDotIndex);
    const extension = fileName.slice(lastDotIndex + 1).toLowerCase();
    const newFileName = `${nameWithoutExtension}.${extension}`;

    return new File([file], newFileName, {
      type: fileType,
      lastModified: file.lastModified,
    });
  });
};
