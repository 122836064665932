import { memo, Fragment, useState, useEffect, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";

import styles from "./answerContainer.module.scss";
import messageStyles from "../message/message.module.scss";
import classNames from "classnames";

import { CopyIcon } from "../icons";
import { UserName } from "../userName";
import { Spinner, Tooltip } from "components";

import { IMessage, IShareChatMessage } from "redux/actions";
import { useSelector } from "redux/hooks";
import { WordHighlighter } from "views/layout/Sidebar/component/ChatSidebar/components/ChatItem/WordHighlighter";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import useRouter from "hooks/useRouter";
import ChatEditIcon from "../icons/ChatEditIcon";
import FileLink from "./FileLink";

interface IProps {
  message?: IMessage;
  messageRef?: React.LegacyRef<HTMLDivElement>;
  shareMessage?: IShareChatMessage;
  shareChat?: boolean;
  chatItem?: IMessage[];
  searchQuery?: string;
  setHighlighted?: React.Dispatch<React.SetStateAction<boolean>>;
  highlighted?: boolean;
  handleSubmit?: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  setImage?: React.Dispatch<React.SetStateAction<string[]>>;
  setFile?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Question = memo(
  ({
    message,
    messageRef,
    searchQuery,
    shareMessage,
    shareChat,
    handleSubmit,
    setMessage,
    setImage,
    setFile,
  }: IProps) => {
    const { userDetail, theme } = useSelector((state) => state.authReducer);
    const { includeRoute } = useRouter();

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const isHighlighted = searchQuery !== "";
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
      setLoading(false);
    };
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      textAreaRef.current?.focus();

      if (textAreaRef.current) {
        const { value } = textAreaRef.current;
        textAreaRef.current.setSelectionRange(value.length, value.length);
      }
      return () => {
        setMessage && setMessage("");
      };
    }, [isEditMode === true]);

    useEffect(() => {
      const intervalID = setTimeout(() => {
        if (isCopied) setIsCopied(false);
      }, 500);

      return () => clearInterval(intervalID);
    }, [isCopied]);

    const isShareChat = includeRoute("/share-chat/");

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit && handleSubmit();
        setIsEditMode(false);
      }
    };

    useEffect(() => {
      if (isEditMode === true) {
        if (message && message?.images && message?.images.length > 0) {
          const images = message?.images.map((img) => {
            return img.path
          })
          setImage!(images);
        }
        else if (message?.files && message.files.length > 0) {
          const files = message.files.map((file) => file.path).filter((path): path is string => path !== null);
          setFile!(files);
        }
        else {
          setFile!([]);
          setImage!([]);
        }

      }
    }, [isEditMode === true])

    return (
      <Fragment>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div ref={messageRef} className={styles.container}>
            <div className={styles.leftContainer}>
              <UserName
                name={userDetail?.user?.name}
                email={userDetail?.user?.email}
              />
              {message?.content || shareMessage?.content ? (
                <div className={styles.messageContainer}>
                  <p
                    className={classNames(messageStyles.message, {
                      [messageStyles.light]: theme === "light",
                      [messageStyles.dark]: theme === "dark",
                    })}
                  >
                    {isEditMode ? (
                      <div className="grid flex-1">
                        <TextareaAutosize
                          ref={textAreaRef}
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            width: "100%",
                            background: "transparent",
                            flexGrow: 1,
                            border: "none",
                            outline: "none",
                            resize: "none",
                            boxSizing: "border-box",
                          }}
                          className={classNames(messageStyles.message, {
                            [messageStyles.light]: theme === "light",
                            [messageStyles.dark]: theme === "dark",
                            [messageStyles.styleTextArea]: true,
                          })}
                          autoFocus
                          defaultValue={
                            message ? message?.content : shareMessage?.content
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            setMessage && setMessage(e.target.value);
                          }}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                    ) : (
                      <div className="flex gap-6">
                        <div
                          className={classNames(messageStyles.userMessage, {
                            [messageStyles.light]: theme === "light",
                            [messageStyles.dark]: theme === "dark",
                            [messageStyles.sharchat]: isShareChat,
                          })}
                          style={{ maxWidth: "73%", width: "auto" }}
                        >
                          <div className={styles.messageContent}>
                            {isHighlighted ? (
                              <WordHighlighter
                                searchQuery={searchQuery || ""}
                                text={String(
                                  message
                                    ? message?.content
                                    : shareMessage?.content
                                )}
                                excerptLength={0}
                              />
                            ) : (
                              <>
                                {message ? (
                                  <div>{message?.content}</div>
                                ) : (
                                  shareMessage?.content
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {!shareMessage?.content && (
                          <div className={styles.EditButton}>
                            <Tooltip
                              control={
                                <div
                                  className="cursor-pointer flex justify-center items-center"
                                  onClick={() => setIsEditMode(!isEditMode)}
                                >
                                  <ChatEditIcon />
                                </div>
                              }
                              placement="top"
                              theme="light"
                            >
                              <div>
                                <FormattedMessage id={"answer.tooltip.edit"} />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </p>
                </div>
              ) :
                (message?.files && message?.files?.length > 0 || (shareMessage && shareMessage?.files && shareMessage.files.length > 0)) ? (
                  <>
                    {message?.files && message.files.length > 0 ? (
                      <FileLink file={message.files} />
                    ) : (
                      shareMessage?.files && shareMessage.files.length > 0 && (
                        <FileLink file={shareMessage.files} />
                      )
                    )}
                  </>
                ) : (
                  (message?.images || (shareMessage && shareMessage?.images?.length > 0 && isShareChat)) && (
                    <div className={styles.imagesContainer}>
                      {message ?
                        message.images.map((image, index) => (
                          <>
                            {loading && (
                              <div>
                                {" "}
                                <Spinner extraSmall />{" "}
                              </div>
                            )}
                            <img
                              key={index}
                              src={image.path}
                              alt={image.path || `Image ${index + 1}`}
                              className={styles.image}
                              onLoad={handleImageLoad}
                            />
                          </>
                        )) :
                        shareMessage && shareMessage?.images.map((image, index) => (
                          <>
                            {loading && (
                              <div>
                                {" "}
                                <Spinner extraSmall />{" "}
                              </div>
                            )}
                            <img
                              key={index}
                              src={image.path}
                              alt={image.path || `Image ${index + 1}`}
                              className={styles.image}
                              onLoad={handleImageLoad}
                            />
                          </>
                        ))}
                    </div>
                  )
                )}
            </div>
          </div>

          {isEditMode && message?.files && (
            <div className={styles.actionContainer}>
              <div className="flex items-center justify-end gap-2 w-full max-w-[800px]">
                <div className="flex items-center gap-2">
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit && handleSubmit();
                      setIsEditMode(false);
                    }}
                    style={{
                      background:
                        "linear-gradient(92.31deg, #8C49F7 0%, #6C53FF 100%)",
                    }}
                    className="px-[20px] py-[10px] text-[12px] md:text-[15px] font-[600] text-white rounded-[100px] cursor-pointer"
                  >
                    <FormattedMessage id={"answer.edit.save"} />
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEditMode(false);
                    }}
                    style={{ border: "1px solid rgba(31, 31, 31, 0.2)" }}
                    className="px-[20px] py-[10px] bg-white text-[12px] md:text-[15px] font-[600] text-black rounded-[100px] cursor-pointer"
                  >
                    <FormattedMessage id={"answer.edit.cancel"} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {
            ((message?.files && message.files.length > 0 && message.content) ||
              (shareMessage?.files && shareMessage.files.length > 0 && shareMessage.content)) && (
              <>
                {message?.files && message.files.length > 0 ? (
                  <div className={styles.container}>
                    <FileLink file={message.files} contentFile={true} />
                  </div>
                ) : (
                  shareMessage?.files && shareMessage.files.length > 0 && (
                    <div className={styles.container}>
                      <FileLink file={shareMessage.files} contentFile={true} />
                    </div>
                  )
                )}
              </>
            )
          }

          {message?.images && message?.content && !shareChat && (
            <div
              className={classNames(styles.imagesContainer, {
                [styles.imageContent]: true,
              })}
            >
              {message.images.map((image, index) => (
                <>
                  {loading && (
                    <div>
                      <Spinner extraSmall />
                    </div>
                  )}
                  <img
                    key={index}
                    src={image.path}
                    alt={image.path || `Image ${index + 1}`}
                    className={styles.image}
                    onLoad={handleImageLoad}
                  />
                </>
              ))}
            </div>
          )}

          {(shareMessage && shareMessage?.content && shareMessage?.images?.length > 0 && isShareChat) && (
            <div className={classNames(styles.imagesContainer, {
              [styles.imageContent]: true,
            })}>
              {shareMessage?.images.map((image, index) => (
                <>
                  {loading && <div> <Spinner extraSmall /> </div>}
                  <img
                    key={index}
                    src={image.path}
                    alt={image.path || `Image ${index + 1}`}
                    className={styles.image}
                    onLoad={handleImageLoad}
                  />
                </>
              ))}
            </div>
          )}
          {!shareChat && !message?.files  && (
            <div className={styles.actionContainer}>
              <div className="flex items-center justify-between gap-2 w-full max-w-[800px]">
                <div className="flex items-center gap-4 mt-3">
                  <Tooltip
                    control={
                      <CopyToClipboard
                        text={String(message?.content)}
                        onCopy={() => setIsCopied(true)}
                      >
                        <div
                          className={classNames(styles.icon, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                          })}
                        >
                          <CopyIcon />
                        </div>
                      </CopyToClipboard>
                    }
                    placement="top"
                    theme="light"
                  >
                    <div>
                      <FormattedMessage
                        id={
                          isCopied
                            ? "answer.tooltip.copied"
                            : "answer.tooltip.copy"
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                {/* /////////////////BUTTONS///////////////*/}
                {isEditMode && (
                  <div className="flex items-center gap-2">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit && handleSubmit();
                        setIsEditMode(false);
                      }}
                      style={{
                        background:
                          "linear-gradient(92.31deg, #8C49F7 0%, #6C53FF 100%)",
                      }}
                      className="px-[20px] py-[10px] text-[12px] md:text-[15px] font-[600] text-white rounded-[100px] cursor-pointer"
                    >
                      <FormattedMessage id={"answer.edit.save"} />
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditMode(false);
                      }}
                      style={{ border: "1px solid rgba(31, 31, 31, 0.2)" }}
                      className="px-[20px] py-[10px] bg-white text-[12px] md:text-[15px] font-[600] text-black rounded-[100px] cursor-pointer"
                    >
                      <FormattedMessage id={"answer.edit.cancel"} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </form>
      </Fragment>
    );
  }
);