import { ReactNode, createContext, useContext, useState } from "react";

export interface ToggleSidebar {
  isOpen: boolean;
  toggleSidebar: () => void;
}

interface IProps {
  children?: ReactNode;
}

const ToggleSidebarContext = createContext<ToggleSidebar | null>(null);

export const ToggleSidebarProvider = ({ children }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const toggleSidebar = () => setIsOpen((prev) => !prev);

  return (
    <ToggleSidebarContext.Provider value={{ isOpen, toggleSidebar }}>
      {children}
    </ToggleSidebarContext.Provider>
  );
};

export const useToggleSidebar = (): ToggleSidebar => {
  const toggleSidebarContext = useContext(ToggleSidebarContext);

  if (!toggleSidebarContext) {
    throw new Error(
      "ToggleSidebarContext must be used within ToggleSidebarContext"
    );
  }

  return toggleSidebarContext;
};
