import { Fragment, memo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./SettingSidebar.module.scss";

import {
  AccountIcon,
  CurrentPlanIcon,
  TeamIcon,
  FeedbackIcon,
  EarnAsAffiliateIcon,
} from "../SettingSidebar/components/icons";

import { Separator } from "components";
import { Credits } from "views/layout/Navbar/components/ChatNav/components/Credits";
import { ThemeSwitch } from "../ChatSidebar/components/ThemeSwitch";

import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";
import { SettingIcon } from "../ChatSidebar/components/icons";
import { DeleteHistory } from "../ChatSidebar/components/icons/DeleteHistory";
import { DeleteChatConfirm } from "../ChatSidebar/components/DeleteChatConfirm";
import { useAppNotification } from "hooks/services/AppNotification";
import { deleteAllConversation } from "redux/actions";

export const SettingSidebar = memo(() => {

  const { triggerNotification } = useAppNotification();
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const { includeRoute } = useRouter();
  const [showConfirmModel, setShowConfirmModel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const itemStyles = (route: string) => {
    return classNames(styles.menuItem, {
      [styles.active]: includeRoute(route),
      [styles.light]: theme === "light",
      [styles.dark]: theme === "dark",
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    deleteAllConversation().then(() => {
      setShowConfirmModel(false);
    }).catch((err) => {
      setShowConfirmModel(false);
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  return (
    <Fragment>
      {showConfirmModel &&
        <DeleteChatConfirm onCancel={() => setShowConfirmModel(false)} onSubmit={handleSubmit} loading={loading} delConversation={true} />
      }
      <div className={styles.hideCredits}>
        <Credits />
      </div>
      <Separator height="20px" />
      <div
        className={styles.container}
      >
        <div className={styles.memu}>
          <div className={classNames(styles.icon, {
            // [styles.light]: theme === 'light' ,
          })}>
            <SettingIcon />
            <h2 className={styles.h2}>
              <FormattedMessage id="sidebar.chat.newChat.settings" />
            </h2>
          </div>
          <NavLink
            data-testid='setting-side-bar-account-btn'
            className={itemStyles(RoutePaths.Account)}
            to={RoutePaths.Account}
          >
            <AccountIcon route={includeRoute(RoutePaths.Account)} />
            <span className={classNames(styles.text, {
              [styles.active]: includeRoute(RoutePaths.Account),
            })}>
              <FormattedMessage id="sidebar.settings.account" />
            </span>

            {/* <span className={styles.optionIcon}><OptionsIcon model={true}/></span> */}
          </NavLink>
          {userDetail?.user.team.role !== "member" && (
            <>
              <NavLink
                data-testid='setting-side-bar-team-btn'
                className={itemStyles(RoutePaths.Team)}
                to={RoutePaths.Team}
              >
                <TeamIcon route={includeRoute(RoutePaths.Team)} />
                <span className={classNames(styles.text, {
                  [styles.active]: includeRoute(RoutePaths.Team),
                })}>
                  <FormattedMessage id="sidebar.settings.myTeam" />
                </span>
              </NavLink>
              {userDetail?.user.team.role === "admin" && (
                <NavLink
                  data-testid='setting-side-bar-current-btn'
                  className={itemStyles(RoutePaths.CurrentPlan)}
                  to={RoutePaths.CurrentPlan}
                >
                  <CurrentPlanIcon theme={theme} route={includeRoute(RoutePaths.CurrentPlan)} settingsidebar={true} />
                  <span className={classNames(styles.text, {
                    [styles.active]: includeRoute(RoutePaths.CurrentPlan),
                  })}>
                    <FormattedMessage id="sidebar.settings.currentPlan" />
                  </span>
                </NavLink>
              )}
            </>
          )}
          <a
            href="https://deftgpt.canny.io/feature-requests/create"
            target="_blank"
            rel="noreferrer"
            className={itemStyles(RoutePaths.Feedback)}
            data-testid='setting-side-bar-feedback-btn'
          >
            <FeedbackIcon />
            <span className={styles.text}>
              <FormattedMessage id="sidebar.settings.giveFeedback" />
            </span>
          </a>

          <Link
            data-testid='setting-side-bar-affiliate-btn'
            to={`/${RoutePaths.Affiliate}`}
            className={itemStyles(RoutePaths.Affiliate)}
          >
            <EarnAsAffiliateIcon />
            <span className={styles.text}>
              <FormattedMessage id="sidebar.settings.earnAsAffiliate" />
            </span>
          </Link>
          {includeRoute(RoutePaths.Account) &&
            <div
              data-testid='setting.del.all.chat'
              className={itemStyles(RoutePaths.Account)}
              onClick={() => setShowConfirmModel(true)}
            ><DeleteHistory settingSideBar={true} />
              <span className={classNames(styles.text, {})}>
                <FormattedMessage id="delete.all.conversation" />
              </span>
            </div>
          }
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <ThemeSwitch />
      </div>
    </Fragment>
  );
});
