import classNames from "classnames";
import styles from "../../chatFooter.module.scss";
import { useSelector } from "redux/hooks";
import { ErrorModal } from "pages/ChatPage/pages/newChat/components/ErrorModal";
import { IFileErrorState } from "pages/ChatPage/pages";
import React from "react";

interface IProp {
  errorModal: IFileErrorState;
  setErrorModal: React.Dispatch<React.SetStateAction<IFileErrorState>>;
  setIsMainScreenOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ErrorModalSection = ({
  errorModal,
  setErrorModal,
  // setIsMainScreenOpen,
}: IProp) => {

  const { theme } = useSelector((state) => state.authReducer);

  return (
    <>
      {errorModal.show === true && (
        <div className={styles.containerCenter}>
          <div
            className={classNames(styles.popupContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <ErrorModal
              uploadURL={true}
              message={errorModal.message}
              onClose={() => {
                // setIsMainScreenOpen!(true)
                setErrorModal({ message: "", show: false });
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorModalSection;
