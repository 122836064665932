import { useEffect, useState, memo } from "react";
import styles from "./ChatItem.module.scss";
import { FormattedMessage } from "react-intl";
import { PriceLoadMoreIcon } from "pages/LandingPage/components/Icons";
import { WordHighlighter } from "./WordHighlighter";
import { ChatIcon, DocumentIcon, ImageIcon } from "../icons";

import { IChat } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  chatItem: IChat;
  onSelectChat?: (itemId: IChat | undefined) => void;
  searchQuery: string;
}

export const ChatSearchItem = memo(
  ({ chatItem, onSelectChat, searchQuery }: IProps) => {


    const [isShowMore, setIsShowMore] = useState<boolean>(false);
    const MAX = 3;
    useEffect(() => {
      if (chatItem.searched_messages) {
        setIsShowMore(chatItem.searched_messages?.length > MAX);
      }
    }, [chatItem.searched_messages]);

    const themeMode = useSelector((state) => state.authReducer.theme);
    return (
      <div
        className="flex flex-col text-prompt-dark whitespace-nowrap overflow-hidden text-ellipsis text-opacity-90">
        <div data-testid='search-chat-btn' onClick={() => onSelectChat?.(chatItem)}>
          <div className="flex items-center text-dark py-1.5 gap-2  whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer ">
            {chatItem.chat_type === "text" && (
              <div className="text-dark">
                <ChatIcon color="currentColor" />
              </div>
            )}
            {chatItem.chat_type === "document" && (
              <div className={styles.actionIcon} style={{ marginLeft: "-2px" }}>
                <DocumentIcon color="currentColor" />
              </div>
            )}
            {(chatItem.chat_type === "image" || chatItem.chat_type === "image_chat" )&& (
              <div className={styles.actionIcon}>
                <ImageIcon color="currentColor" />
              </div>
            )}
            <div className="flex-1 text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis ">
              <WordHighlighter
                searchQuery={searchQuery}
                text={chatItem.topic || "New Chat"}
                excerptLength={32}
              />
            </div>
          </div>
          {chatItem?.searched_messages &&
            chatItem.searched_messages.map(
              (message, index) =>
                ((isShowMore && index < MAX) || !isShowMore) && (
                  <div
                    className="flex flex-col pl-8 py-1.5 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis hover:bg-dark-4 dark:hover:bg-white-8 hover:rounded-md"
                    key={index}
                  >
                    <WordHighlighter
                      searchQuery={searchQuery}
                      text={String(message?.content)}
                      excerptLength={24}
                    />
                  </div>
                )
            )}
        </div>
        {isShowMore && (
          <span
            data-testid='sidebar-chat-view-more'
            className="flex items-center gap-1 pl-8 cursor-pointer text-sm mt-2"
            onClick={() => setIsShowMore(false)}
          >
            <PriceLoadMoreIcon viewMore={true} themecolor={themeMode} />
            <FormattedMessage id="sidebar.chat.view.more" />
          </span>
        )}
      </div>
    );
  }
);
