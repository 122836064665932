import {
  useEffect, useState, memo,
  Dispatch, SetStateAction
} from "react";

import { theme } from "theme";
import classNames from "classnames";
import styles from "./ChatItem.module.scss";

import { Spinner, Tooltip } from "components";
import { WordHighlighter } from "./WordHighlighter";

import {
  ChatIcon,
  DocumentIcon,
  EditIcon,
  // DeleteIcon,
  TickIcon,
  RemoveIcon,
  ImageIcon,
} from "../icons";

import { IChat, updateChatHistory, deleteChatHistory } from "redux/actions";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";

interface IProps {
  chatItem: IChat;
  selectedItem?: IChat;
  onSelectChat?: (itemId: IChat | undefined) => void;
  searchQuery: string;
  setChatHistory: Dispatch<SetStateAction<any>>;
  chatHistoryPagination: IChat[];
  handleSelectId: (id: number) => void;
  selectedChatIds: number[];
}

export const ChatItem = memo(
  ({ chatItem, selectedItem, onSelectChat, searchQuery, setChatHistory, chatHistoryPagination, handleSelectId, selectedChatIds }: IProps) => {
    const themeMode = useSelector((state) => state.authReducer.theme);
    const { triggerNotification } = useAppNotification();
    const { push } = useRouter();

    const isSearch: boolean = searchQuery.length > 0 ? true : false;
    const isActive: boolean = selectedItem?.id === chatItem.id;

    const [message, setMessage] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [isloading, setIsloading] = useState<boolean>(false);
    // const [selectedChatIds, setSelectedChatIds] = useState<number[]>([]);

    useEffect(() => setMessage(selectedItem?.topic as string), [selectedItem]);

    useEffect(() => {
      if (!message && !isEdit) setMessage(selectedItem?.topic as string);
    }, [selectedItem, message, isEdit]);

    const toggleEdit = () =>
      setIsEdit((prev) => {
        return !prev;
      });

    const onEdit = () => {
      setIsloading(true);
      updateChatHistory(selectedItem?.id as number, message)
        .then((resp: any) => {
          let index = chatHistoryPagination.findIndex(
            (chat) => chat.id === resp.id
          );
          if (index >= 0) {
            const updatedChatHistory = [...chatHistoryPagination];
            updatedChatHistory[index] = { ...updatedChatHistory[index], topic: resp.topic };
            setChatHistory(updatedChatHistory);
            setTimeout(() => {
              setIsloading(false);
              setMessage("");
              toggleEdit();
            }, 500);

          }
        })
        .catch((err) => {
          setIsloading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    };

    const toggleDelete = () =>
      setIsDelete((prev) => {
        return !prev;
      });

    const onDelete = () => {
      setIsloading(true);
      deleteChatHistory(selectedItem?.id as number)
        .then((resp: any) => {
          if (resp?.message === "Chat deleted") {
            const deletedChat = chatHistoryPagination.filter(
              (chat) => chat.id !== selectedItem?.id
            )
            setChatHistory(deletedChat);
          }
          toggleDelete();
          setIsloading(false);
          push(`/${RoutePaths.Chat}`);
        })
        .catch((err) => {
          setIsloading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    };

    const onConfirm = () => {
      if (isDelete) onDelete();
      else onEdit();
    };

    const onCancel = () => {
      if (isDelete) toggleDelete();
      else toggleEdit();
    };

    const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>,) => {
      e.stopPropagation(); 
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      e.stopPropagation();
      handleSelectId(id);
    };
    return (
      <Tooltip
        control={
          <div
            className={classNames(styles.itemContainer, {
              [styles.itemContainerDark]: themeMode === "dark",
              [styles.itemContainerLight]: themeMode === "light",
              [styles.active]: isActive,
            })}
            onClick={() => onSelectChat?.(chatItem)}
            data-testid='select-chat'
          >
            <div className={styles.check}>
              <input type="checkbox"
                checked={selectedChatIds.includes(chatItem.id)}
                onChange={(e) => handleCheckboxChange(e, chatItem.id)}
                onClick={(e) => handleCheckboxClick(e)}
              />
              {chatItem.chat_type === "text" && (
                <div className={styles.actionIcon}>
                  <ChatIcon color={theme.primaryColor} chatList={true} />
                </div>
              )}
              {(chatItem.chat_type === "document") && (
                <div className={styles.actionIcon} style={{ marginLeft: "-2px" }}>
                  <DocumentIcon color="#2DBF3C" chatList={true} />
                </div>
              )}
              {(chatItem.chat_type === "image" || chatItem.chat_type === "image_chat") && (
                <div className={styles.actionIcon}>
                  <ImageIcon color="#FFA439" chatList={true} />
                </div>
              )}
            </div>
            <div
              className={classNames(styles.itemMsgActionContainer, {
                [styles.itemMsgActionContainerLight]: themeMode === "light",
                [styles.itemMsgActionContainerDark]: themeMode === "dark",
                [styles.active]: !isSearch && isActive,
              })}
            >
              <div className={classNames(styles.itemMessageContainer)}>
                {isEdit ? (
                  <input
                    className={classNames(styles.editItemTextfield, {
                      [styles.light]: themeMode === "light",
                      [styles.dark]: themeMode === "dark",
                    })}
                    type="text"
                    autoFocus
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    data-testid='edit-input'
                  />
                ) : (
                  <div
                    className={classNames(styles.itemText, {
                      [styles.isActive]: !isSearch && isActive,
                    })}
                  >
                    <WordHighlighter
                      searchQuery={searchQuery}
                      text={chatItem.topic || "New chat"}
                      excerptLength={20}
                    />
                  </div>
                )}
              </div>
              {isActive && !isSearch && !isEdit && !isDelete && (
                <div className={styles.actContainer}>
                  <div data-testid='edit-btn' className={styles.actionIcon} onClick={toggleEdit}>
                    <EditIcon />
                  </div>
                  {/* <div data-testid='del-btn' className={styles.actionIcon} onClick={toggleDelete}>
                    <DeleteIcon />
                  </div> */}
                </div>
              )}
              {isEdit && (
                <div className={styles.actionsContainer}>
                  {isloading ? (
                    <Spinner extraSmall isWhite={themeMode === 'light'} />
                  ) : (
                    <div data-testid='confirm-edit-btn' className={styles.actionIcon} onClick={onConfirm}>
                      <TickIcon />
                    </div>
                  )}
                  <div data-testid='cancel-edit-btn' className={styles.actionIcon} onClick={onCancel}>
                    <RemoveIcon />
                  </div>
                </div>
              )}
              {isDelete && (
                <div className={styles.actionsContainer}>
                  <div data-testid='cancel-delbtn' className={styles.actionIcon} onClick={onCancel}>
                    <RemoveIcon />
                  </div>
                  {isloading ? (
                    <Spinner extraSmall isWhite={themeMode === 'light'} />
                  ) : (
                    <div data-testid='confirm-del-btn' className={styles.actionIcon} onClick={onConfirm}>
                      <TickIcon />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        }
        placement="right"
        theme="light"
      >
        {chatItem.topic || "New Chat"}
      </Tooltip>
    );
  }
);
