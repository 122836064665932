import { Tooltip } from "components";
import { FormattedMessage } from "react-intl";

import {
  // DocIcon,
  //   CopyIcon,
  ShareIcon,
  RegenerateIcon,
} from "../icons";

import { IChatModel, IMessage, IShareChatMessage } from "redux/actions";

import classNames from "classnames";
import { useSelector } from "redux/hooks";
import styles from "../messageContainer/answerContainer.module.scss";

interface IProps {
  share?: boolean;
  shareChat?: boolean;
  shareMessage?: IShareChatMessage;
  messageIndex?: number;
  message?: IMessage;
  toggleShareChat?: () => void;
  handleCheckboxChange?: (messageId: any) => void;
  onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
}

export const AssistantMessageAction = ({
  messageIndex,
  message,
  share,
  onRegenerate,
  toggleShareChat,
  handleCheckboxChange,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <div className={styles.actionContainer}>
      {/* REGENERATE BUTTON */}
      <div className="flex flex-row items-center gap-6 mt-2">
        <Tooltip
          control={
            <div
              className={classNames(styles.icon, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
              onClick={() =>
                onRegenerate &&
                onRegenerate(messageIndex ? messageIndex : 0, {} as IChatModel)
              }
              data-testid="regenerate-answer-btn"
            >
              <RegenerateIcon />
            </div>
          }
          placement="top"
          theme="light"
        >
          <div>
            <FormattedMessage id="answer.tooltip.regenerate" />
          </div>
        </Tooltip>
        {!share && (
          <Tooltip
            control={
              <div
                className={classNames(styles.icon, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                onClick={() => {
                  toggleShareChat!();
                  handleCheckboxChange!(message?.id);
                }}
                style={{ marginLeft: "-12px" }}
                data-testid="share-chat-btn"
              >
                <ShareIcon />
              </div>
            }
            placement="top"
            theme="light"
          >
            <div>
              <FormattedMessage id="share.tooltip.chat" />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
