import { useState, useEffect } from "react";

import classNames from "classnames";
import styles from "./docQuestion.module.scss";

import { useSelector } from "redux/hooks";
import { PlusIcon } from "pages/ChatPage/components/chatFooter/components/Icons";

interface IProps {
  question?: string;
  onClick?: () => void;
  delay?: number;
}

export const DocQuestion = ({ question, onClick, delay }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  if (!showComponent) return null;
  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      onClick={onClick}
      data-testid='document-question'
    >
      <div className={styles.text}>{question}</div>
      <PlusIcon  />
    </div>
  );
};
