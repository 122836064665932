interface IProps {
  color?: string;
  chatList?: boolean
}

export const ChatIcon = ({ color = "#FFFFFF", chatList }: IProps) => {
  return (
    <svg
      width= {chatList? "16":"22"}
      height={chatList? "16":"22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
          d="M1.83301 15.5835V2.796C1.83301 2.58211 1.93231 2.36822 2.13092 2.15433C2.32954 1.94044 2.53579 1.8335 2.74967 1.8335H14.6434C14.8726 1.8335 15.0865 1.93662 15.2851 2.14287C15.4837 2.34912 15.583 2.56683 15.583 2.796V10.9543C15.583 11.1682 15.4837 11.3821 15.2851 11.596C15.0865 11.8099 14.8726 11.9168 14.6434 11.9168H5.49967L1.83301 15.5835ZM6.43926 16.5002C6.22537 16.5002 6.0153 16.3932 5.80905 16.1793C5.6028 15.9654 5.49967 15.7516 5.49967 15.5377V13.2918H16.958V5.50016H19.2497C19.4636 5.50016 19.6698 5.60711 19.8684 5.821C20.067 6.03488 20.1663 6.25641 20.1663 6.48558V20.1439L16.5226 16.5002H6.43926ZM14.208 3.2085H3.20801V12.2606L4.92676 10.5418H14.208V3.2085Z"
          fill={color}
        />
    </svg>
  );
};
