export interface IAction {
  readonly type: string;
}

export enum TYPES {
  // AUTHENTICATION
  SIGN_IN = "SIGN_IN",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  SOCIAL_CALLBACK = "SOCIAL_CALLBACK",
  LOGOUT = "LOGOUT",
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
  INIT_USER_DETAILS = "INIT_USER_DETAILS",
  UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS",

  // CHAT MODELS
  GET_CHAT_MODELS = "GET_CHAT_MODELS",
  SET_CHAT_MODEL = "SET_CHAT_MODEL",
  SET_IMAGE_ATTRIBUTE = "SET_IMAGE_ATTRIBUTE",
  SET_SHOW_UPSCALE_MODAL = "SET_SHOW_UPSCALE_MODAL",

  // CHAT
  START_NEW_CHAT = "START_NEW_CHAT",
  NEW_CHAT = "NEW_CHAT",
  ANSWER_NEW_CHAT = "ANSWER_NEW_CHAT",
  REGULAR_CHAT = "REGULAR_CHAT",
  ANSWER_REGULAR_CHAT = "ANSWER_REGULAR_CHAT",
  GET_ALL_CHATS = "GET_ALL_CHATS",
  ADD_CHAT_HISTORY = "ADD_CHAT_HISTORY",
  UPDATE_CHAT_HISTORY = "UPDATE_CHAT_HISTORY",
  DELETE_CHAT_HISTORY = "DELETE_CHAT_HISTORY",
  GET_CHAT_HISTORY = "GET_CHAT_HISTORY",
  SAVE_FILE_PATH = "SAVE_FILE_PATH",
  GET_GENERATED_QUESTIONS_NEW = "GET_GENERATED_QUESTIONS_NEW",
  GET_GENERATED_QUESTIONS_HISTORY = "GET_GENERATED_QUESTIONS_HISTORY",
  REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES = "REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES",
  REMOVE_RELATED_QUESTIONS_FROM_MESSAGES = "REMOVE_RELATED_QUESTIONS_FROM_MESSAGES",
  REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES = "REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES",
  REMOVE_LAST_MESSAGE_FROM_MESSAGES = "REMOVE_LAST_MESSAGE_FROM_MESSAGES",
  GET_SHARE_CHAT = "GET_SHARE_CHAT",
  UPDATED_NEW_REGULAR_CHAT="UPDATED_NEW_REGULAR_CHAT",
  UPDATE_REGULAR_CHAT="UPDATE_REGULAR_CHAT",
  GET_CHAT_SETTING="GET_CHAT_SETTING",
  DELETE_MULTIPLE_CHAT_HISTORY="DELETE_MULTIPLE_CHAT_HISTORY",
  CLEAR_NEW_MESSAGES='CLEAR_NEW_MESSAGES',

  // IMAGE GENERATION
  GENERATE_IMAGE_ERROR = "GENERATE_IMAGE_ERROR",
  UPSCALE_IMAGE = "UPSCALE_IMAGE",

  // PLAN SUBSCRIPTION
  GET_PLAN = "GET_PLAN",
  GET_ACTIVE_PLAN = "GET_ACTIVE_PLAN",
  GET_CREDITS_LIST = "GET_CREDITS_LIST",
  UPDATE_CREDITS = "UPDATE_CREDITS",
  GET_CREDIT_LIMITS = "GET_CREDIT_LIMITS",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",

  // TEAM
  GET_ALL_TEAMS = "GET_ALL_TEAMS",
  SELECT_TEAM = "SELECT_TEAM",
  GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS",
  GET_TEAM_INFO = "GET_TEAM_INFO",
  INVITE_TEAM_MEMBER = "INVITE_TEAM_MEMBER",
  REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER",
  UPDATE_MEMBER_ROLE = "UPDATE_MEMBER_ROLE",
  UPDATE_MEMBER_DAILY_LIMIT = "UPDATE_MEMBER_DAILY_LIMIT",
  RESEND_INVITE = "RESEND_INVITE",
  CANCEL_INVITE = "CANCEL_INVITE",

  // SYSTEM
  SET_SYSTEM_THEME = "SET_SYSTEM_THEME",
  GET_SYSTEM_THEME = "GET_SYSTEM_THEME",


  // PROPMT LIBRARY
  GET_ALL_PROMPT = "GET_ALL_PROMPT",
  GET_USER_PROMPT="GET_USER_PROMPT",
  DELETE_USER_PROMPT='DELETE_USER_PROMPT',
  UPDATE_USER_PROMPT='UPDATE_USER_PROMPT'
}
